import React from 'react'
import tw, { theme, styled } from 'twin.macro'
//
import { Heading } from '~storybook/blocks/typography/index'
import { ImageProxy } from '~storybook/image/image'
import { Html } from '~storybook/blocks/html/html'
import Icons from '~storybook/icons/index'

const CardWrapper = styled.div`
  ${tw`flex flex-col md:flex-row mb-8`}

  min-height: 500px;

  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    min-height: 430px;
  }

  @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
    min-height: 600px;
  }
`

const LogoWrapper = styled.div`
  img {
    height: 80px !important;
    width: auto !important;
  }
`

const ImageWrapper = styled.div`
  ${tw`w-full md:w-1/2 lg:w-3/5`}
  &, img {
    min-height: 500px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      min-height: 430px;
    }

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      min-height: 207px;
    }
  }
`

const CardLocation = ({ image = '', logo = '', title = '', address = '', telephone = '', email = '' }) => {
  return (
    <CardWrapper>
      <ImageWrapper>
        {image && (
          <ImageProxy
            className="absolute w-full h-full top-0 left-0"
            objectFit="cover"
            {...{
              url: image,
              type: 'fluid',
              params: {
                mw: 808,
                mh: 570,
                g: 'sm',
                r: 'fill'
              }
            }}
          />
        )}
      </ImageWrapper>
      <div className="flex flex-col w-full md:w-1/2 lg:w-2/5 px-6 md:px-10 lg:px-10 pb-6 md:pb-10 lg:pb-10 pt-8 md:pt-10 lg:pt-10 bg-c-card-location justify-between">
        <div>
          {title && <Heading className="mb-6 text-c-card-location-fg font-medium" level="h3" text={title} />}
          {address && (
            <div className="flex flex-row text-c-card-location-locationAttr mb-4">
              <Icons.LocationPin />
              <div className="ml-4 text-c-card-location-fg">
                <Html value={address} />
              </div>
            </div>
          )}
          {telephone && (
            <div className="flex flex-row text-c-card-location-locationAttr mb-4">
              <Icons.Telephone />
              <div className="ml-4 text-c-card-location-fg">
                <Html value={telephone} />
              </div>
            </div>
          )}
          {email && (
            <div className="flex flex-row text-c-card-location-locationAttr mb-4">
              <Icons.Mail />
              <div className="ml-4 text-c-card-location-fg">
                <Html value={email} />
              </div>
            </div>
          )}
        </div>
        <div>
          {logo && (
            <LogoWrapper>
              <ImageProxy
                objectFit="contain"
                {...{
                  url: logo,
                  type: 'fixed',
                  params: {
                    w: 217,
                    h: 82,
                    r: 'fit'
                  }
                }}
              />
            </LogoWrapper>
          )}
        </div>
      </div>
    </CardWrapper>
  )
}

export default CardLocation
