import React from 'react'
import tw, {css } from 'twin.macro'
import parse from 'style-to-object';
//
import FormattedText from '~utils/text'

export const ParagraphText = ({ 
  uuid, 
  fieldText, 
  fieldBreakpoints, 
  fieldCss, 
  fieldAnchorLinkId, 
  entityPublished 
}) => {

  if (!fieldText || !entityPublished) {
    return null
  }

  const { value, format, processed } = fieldText

  if (!value) {
    return null
  }

  const bkPointStyles = {
    Block: css`${tw`block`}`,
    
    Small: css`${tw`hidden sm:block`}`,
    Medium: css`${tw`hidden md:block`}`,
    Large: css`${tw`hidden lg:block`}`,
    XLarge: css`${tw`hidden xl:block`}`,

    FromSmall: css`${tw`hidden sm:block`}`,
    FromMedium: css`${tw`hidden md:block`}`,
    FromLarge: css`${tw`hidden lg:block`}`,
    FromXLarge: css`${tw`hidden xl:block`}`,

    TillSmall: css`${tw`block sm:hidden`}`,
    TillMedium: css`${tw`block md:hidden`}`,
    TillLarge: css`${tw`block lg:hidden`}`,
    TillXLarge: css`${tw`block xl:hidden`}`,
  }

  const CssFromContent = parse(fieldCss)
  const anchorID = fieldAnchorLinkId ? fieldAnchorLinkId : uuid

  return (
    <section className={`bg-white-500 w-full mb-8 relative`} id={anchorID} css={[fieldBreakpoints && bkPointStyles?.[fieldBreakpoints]]} style={CssFromContent} >
      <FormattedText text={processed} format={format} />
    </section>
  )
}

export default ParagraphText
