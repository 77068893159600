import React from 'react'
import { theme, styled } from 'twin.macro'
//
import TimelineCard from './timeline-card'

const CardWrapper = styled.div`
  .line {
    width: 2px;
    height: 100%;
    background: ${() => theme`colors['c-timeline-card'].cardLineBg`};

    .circle {
      width: 22px;
      height: 22px;
      border: solid 2px;
      border-radius: 50%;
      background: ${() => theme`colors['c-timeline-card'].cardCircleBg`};
      border-color: ${() => theme`colors['c-timeline-card'].cardCircleBorder`};
    }

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      display: none;
    }
  }

  @media (min-width: ${theme`screens.md`}) and (max-width: ${`${
      parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1
    }px`}) {
    padding-left: 60px;
    display: block;
    position: relative;

    .line {
      position: absolute;
      left: 11px;
      top: 3px;

      .circle {
        position: absolute;
        left: -11px;
        top: 0;
      }
    }
  }

  @media (min-width: ${theme`screens.lg`}) {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-items: center;

    .card-container,
    .time-point-label {
      width: 420px;
      display: flex;
      flex: 1;
    }

    .time-point-label {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }

    .line-container {
      width: 26px;
      display: block;
      text-align: center;
      position: relative;

      .line {
        position: absolute;
        left: 50%;
        top: 0px;

        .circle {
          position: absolute;
          left: -10px;
          top: calc(50% - 10px);
        }
      }
    }

    &:nth-child(1) {
      .line-container .line {
        top: calc(50% - 10px);
        height: 100%;

        .circle {
          top: 0;
        }
      }
    }

    &:nth-child(2n - 1) {
      flex-direction: row-reverse;

      .card-container {
        flex-direction: row-reverse;
        > div {
          margin-right: 132px;
        }

        &.compact > div {
          margin-right: 32px;
        }
      }

      .time-point-label {
        span {
          padding-left: 32px;
        }
      }
    }
    &:nth-child(2n) {
      .time-point-label {
        flex-direction: column-reverse;
      }

      .card-container {
        > div {
          margin-left: 132px;
        }
        &.compact > div {
          margin-left: 32px;
          margin-top: 8px;
        }
      }

      .time-point-label {
        text-align: right;
        span {
          padding-right: 32px;
        }
      }
    }
  }
`

const Timeline = ({ cards, compact = false }) => {
  return (
    <div>
      {Array.isArray(cards) &&
        cards.map((card) => (
          <CardWrapper key={`timeline-item-${card?.id}`}>
            <div className="time-point-label block relative text-xl text-c-timeline-card-title font-bold mb-4 md:mb-10 lg:mb-0">
              <span>{card?.title}</span>
            </div>
            <div className="line-container">
              <div className="line">
                <div className="circle" />
              </div>
            </div>
            <div className={`card-container block relative pb-0 md:pb-8 lg:pb-0 ${compact ? 'compact' : ''}`}>
              <TimelineCard
                {...{
                  image: card?.image,
                  title: card?.cardTitle,
                  text: card?.cardText,
                  cta: card?.cta,
                  showCta: card?.showCta
                }}
              />
            </div>
          </CardWrapper>
        ))}
    </div>
  )
}

export default Timeline
