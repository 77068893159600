import React, { useContext } from 'react'
//
import SocialLinks from '~storybook/social-links'
import { SiteDataContext } from '~components/layout/wrappers'

export const ParagraphSocialMedia = ({ id, fieldBackgroundColor }) => {
  const siteData = useContext(SiteDataContext)

  const { group } = siteData
  const {
    fieldSocialmediaFacebook,
    fieldSocialmediaLinkedin,
    fieldSocialmediaTwitter,
    fieldSocialmediaInstagram
  } = group

  return (
    <SocialLinks
      key={`social-media-${id}`}
      dark={fieldBackgroundColor === 'dark'}
      links={{
        twitter: { url: fieldSocialmediaTwitter?.url?.path, label: 'Twitter' },
        facebook: { url: fieldSocialmediaFacebook?.url?.path, label: 'Facebook' },
        linkedin: {
          url: fieldSocialmediaLinkedin?.url?.path,
          label: 'LinkedIn'
        },
        instagram: { url: fieldSocialmediaInstagram?.url?.path, label: 'Instagram' }
      }}
    />
  )
}

export default ParagraphSocialMedia
