import React from 'react'
//
import Downloads from '~storybook/downloads'

const ParagraphDownloads = ({ fieldDownloadsTitle, fieldFile = [] }) => {
  const files = fieldFile?.map((file) => file?.entity)

  return <Downloads title={fieldDownloadsTitle} files={fieldFile}/>
}

export default ParagraphDownloads
