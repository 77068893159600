import React from 'react'
import tw, { theme, styled } from 'twin.macro'
//
import { ImageProxy } from '~storybook/image/image'

const ImageCredits = styled.div`
  ${tw`absolute bottom-0 right-0 text-white py-2 px-3 text-sm z-10`}
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${theme`colors['c-gallery-image'].bg`};
    opacity: 0.9;
    z-index: -1;
  }
`

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
      width: auto !important;
      height: auto !important;
      margin: 0px auto;
    }
  }
`

const GalleryImage = ({ image, compact = false }) => {
  const imageParams = {
    mw: compact ? 640 : 790,
    mh: compact ? 360 : 444,
    g: 'sm',
    r: 'fit'
  }

  const imageBlock = (
    <figure>
      <ImageWrapper className="relative block">
        <ImageProxy url={image?.url} noCrop type="fluid" params={imageParams} />
        {image?.credits && <ImageCredits>{image?.credits}</ImageCredits>}
      </ImageWrapper>
    </figure>
  )

  return <div>{imageBlock}</div>
}

export default GalleryImage
