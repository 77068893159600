import React from 'react'

export const QuoteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
      <defs>
        <path
          id="quoteicon"
          d="M6.611 19c.963 0 1.806-.328 2.528-.985.722-.656 1.083-1.446 1.083-2.369 0-1.064-.481-1.951-1.444-2.661L11 5H7.667l-3.723 7.559c-.37.745-.62 1.34-.75 1.783-.13.444-.194.879-.194 1.304 0 .923.352 1.713 1.056 2.37.703.656 1.555.984 2.555.984zm9 0c.963 0 1.806-.328 2.528-.985.722-.656 1.083-1.446 1.083-2.369 0-1.064-.481-1.951-1.444-2.661L20 5h-3.333l-3.723 7.559c-.37.745-.62 1.34-.75 1.783-.13.444-.194.879-.194 1.304 0 .923.352 1.713 1.056 2.37.703.656 1.555.984 2.555.984z"
        />
      </defs>
      <g fill="#353543" fillRule="evenodd">
        <mask id="quoteiconmask" fill="#fff">
          <use xlinkHref="#quoteicon" />
        </mask>
        <use fillRule="nonzero" xlinkHref="#quoteicon" />
        <g mask="url(#quoteiconmask)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export const TwitterVerified = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25a3.606 3.606 0 00-1.336-.25c-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5a.749.749 0 01-1.041.208l-.115-.094-2.415-2.415a.749.749 0 111.06-1.06l1.77 1.767 3.825-5.74a.75.75 0 011.25.833z" />
    </svg>
  )
}

export const TwitterComments = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828a.85.85 0 00.12.403.744.744 0 001.034.229c.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67a.75.75 0 00-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z" />
    </svg>
  )
}

export const TwitterLike = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z" />
    </svg>
  )
}

export default { QuoteIcon, TwitterVerified, TwitterComments, TwitterLike }
