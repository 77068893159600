/* eslint-disable no-unused-expressions */
import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { isToday, isBefore, isAfter, addDays, getUnixTime, differenceInWeeks, differenceInMonths } from 'date-fns'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import { useIntl } from 'react-intl'
//
import { SiteDataContext } from '~components/layout/wrappers'
import Filters from '~storybook/filters/index'
import { AgendaLayout } from '~storybook/layout/index'
import Button from '~storybook/button/index'
import { TodayEvents, EventsList } from '~storybook/agenda'
import { formatTZ } from '~utils/dates'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const agendaQuery = graphql`
  query AgendaViewQuery {
    drupal {
      groupQuery(limit: 1000) {
        entities {
          ... on Drupal_GroupWebsite {
            id
            reverseGidGroupContent(
              limit: 50000
              filter: { conditions: { field: "type.target_id", value: "website-group_node-news" } }
            ) {
              entities {
                __typename
                ... on Drupal_GroupContentWebsiteGroupNodeNews {
                  queryEntityId(filter: { conditions: { field: "field_type", value: "event" } }) {
                    entities {
                      entityTranslations {
                        ... on Drupal_NodeNews {
                          langcode {
                            value
                          }
                          title
                          nid
                          fieldEventLocation
                          fieldInThePicture
                          fieldNewsDate {
                            date
                            value
                          }
                          fieldTypeLabel
                          fieldNewsTopics {
                            entity {
                              tid
                              name
                            }
                          }
                          fieldNewsSummary
                          path {
                            alias
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const buildInitialAgendaItems = ({ data, group, fieldWhitelist, fieldBlacklist, language }) => {
  const whitelistTopics = []
  const blacklistTopics = []

  if (fieldWhitelist) {
    fieldWhitelist?.forEach((entity) => {
      whitelistTopics.push(entity?.entity?.tid)
    })
  }
  if (fieldBlacklist) {
    fieldBlacklist?.forEach((entity) => {
      blacklistTopics.push(entity?.entity?.tid)
    })
  }

  const items = []
  const todayEvents = []

  const groupNews =
    data?.drupal?.groupQuery?.entities
      ?.find((entity) => entity.id === group.id)
      ?.reverseGidGroupContent?.entities?.map((entity) => entity?.queryEntityId?.entities)
      ?.map((entity) => entity?.[0]?.entityTranslations) || []

  groupNews.forEach((entities) => {
    const translation = entities?.find((entity) => entity?.langcode?.value === language)
    if (translation && translation?.fieldNewsDate?.date) {
      const { nid, title, fieldNewsSummary, fieldInThePicture, fieldNewsDate, fieldEventLocation, path } = translation
      const topics = translation?.fieldNewsTopics?.map((topic) => {
        return { ...topic.entity }
      })
      const timestamp = getUnixTime(new Date(translation?.fieldNewsDate?.value))
      const newsDate = new Date(translation?.fieldNewsDate?.value)
      const dateStr = newsDate && `${formatTZ(newsDate, 'd LLLL yyyy | HH:mm', { locale: localesObj?.[language] })}`
      const timeStr = newsDate && `${formatTZ(newsDate, 'HH:mm', { locale: localesObj?.[language] })}`
      const dateObj = new Date(fieldNewsDate?.value)

      const item = {
        id: nid,
        date: dateObj,
        location: fieldEventLocation,
        dateStr: dateStr,
        timeStr: timeStr,
        highlighted: fieldInThePicture,
        title,
        summary: fieldNewsSummary,
        topics,
        timestamp,
        url: path?.alias ? `/${language}${path?.alias}` : `/${language}/node/${nid}`
      }

      items.push(item)

      if (isToday(dateObj)) {
        todayEvents.push(item)
      }
    }
  })

  const filteredItems = items.filter((entity) => {
    if (
      fieldWhitelist?.length &&
      (!entity.topics?.length || !entity.topics?.some((topic) => whitelistTopics.includes(topic.tid)))
    ) {
      return false
    }

    if (fieldBlacklist?.length && entity?.topics?.some((topic) => blacklistTopics.includes(topic.tid))) {
      return false
    }

    return true
  })

  /* eslint-disable no-nested-ternary */
  // filteredItems.sort((a, b) => (a?.timestamp < b?.timestamp ? 1 : b?.timestamp < a?.timestamp ? -1 : 0))
  filteredItems.sort((a, b) => (b?.timestamp < a?.timestamp ? 1 : a?.timestamp < b?.timestamp ? -1 : 0))
  todayEvents.sort((a, b) => (b?.timestamp < a?.timestamp ? 1 : a?.timestamp < b?.timestamp ? -1 : 0))
  /* eslint-disable no-nested-ternary */

  return { allItems: filteredItems, todayEvents }
}

const buildInitialFilters = ({ intl }) => {
  return [
    {
      id: 'dates',
      type: 'radio',
      label: intl.formatMessage({ id: 'filter.dates' }),
      options: [
        {
          name: 'all',
          label: intl.formatMessage({ id: 'filter.all' })
        },
        {
          name: 'today',
          label: intl.formatMessage({ id: 'filter.today' })
        },
        {
          name: 'currentweek',
          label: intl.formatMessage({ id: 'filter.currentWeek' })
        },
        {
          name: 'pastweek',
          label: intl.formatMessage({ id: 'filter.pastWeek' })
        },
        {
          name: 'last3months',
          label: intl.formatMessage({ id: 'filter.last3Months' })
        },
        {
          name: 'last6months',
          label: intl.formatMessage({ id: 'filter.last6Months' })
        },
        {
          name: 'lastyear',
          label: intl.formatMessage({ id: 'filter.lastYear' })
        },
        {
          name: 'range',
          label: intl.formatMessage({ id: 'filter.specificDates' }),
          showCalendarRange: true
        }
      ]
    }
  ]
}

const applyFilters = (items, filters) => {
  let itemsToFilter = [...items]

  if (filters?.str) {
    const searchStr = filters?.str?.toLowerCase()
    itemsToFilter = items.filter((item) => {
      return item?.title?.toLowerCase()?.includes(searchStr) || item?.summary?.toLowerCase()?.includes(searchStr)
    })
  }

  const selectedDates = filters?.filters
    ?.filter((filter) => filter.id === 'dates')?.[0]
    ?.options?.filter((opt) => opt.checked)
    .map((opt) => opt?.name)

  const filteredItems = itemsToFilter?.filter((item) => {
    let valid = true
    if (valid && Array.isArray(selectedDates) && selectedDates.length > 0) {
      const curDate = new Date()
      if (selectedDates[0] === 'all') {
        return true
      }
      if (selectedDates[0] === 'today') {
        valid = isToday(item?.date)
      }
      if (selectedDates[0] === 'currentweek') {
        const diff = differenceInWeeks(curDate, item?.date)
        valid = diff === 0
      }
      if (selectedDates[0] === 'pastweek') {
        const diff = differenceInWeeks(curDate, item?.date)
        valid = diff === 1
      }
      if (selectedDates[0] === 'last3months') {
        const diff = differenceInMonths(curDate, item?.date)
        valid = diff < 3
      }
      if (selectedDates[0] === 'last6months') {
        const diff = differenceInMonths(curDate, item?.date)
        valid = diff < 6
      }
      if (selectedDates[0] === 'lastyear') {
        const diff = differenceInMonths(curDate, item?.date)
        valid = diff < 12
      }
      if (selectedDates[0] === 'range') {
        let isValidRange = true
        if (isValidRange && filters.startDate) {
          isValidRange = isAfter(item?.date, new Date(filters.startDate))
        }
        if (isValidRange && filters.endDate) {
          isValidRange = isBefore(item?.date, addDays(new Date(filters.endDate), 1))
        }

        return isValidRange
      }
    }

    return valid
  })

  return filteredItems
}

export const ParagraphAgenda = ({
  fieldShowEventsOftheDay,
  fieldUpcomingEventsTitle,
  fieldEventsOfTheDayTitle,
  fieldNoUpcomingEventTitle,
  fieldFilteredEventsTitle,
  fieldNoFilteredEventsTitle,
  fieldBlacklist,
  fieldWhitelist,
  hasSidebar,
  language
}) => {
  const siteData = useContext(SiteDataContext)
  const { group } = siteData
  const intl = useIntl()

  const initialFilterOptions = buildInitialFilters({ intl })
  const [filterOptions, setFilterOptions] = useState(initialFilterOptions)
  const [filters, setFilters] = useState()
  const [filterKey, setFilterKey] = useState('search-key_0')

  const data = useStaticQuery(agendaQuery)

  const { allItems, todayEvents } = buildInitialAgendaItems({ data, group, fieldWhitelist, fieldBlacklist, language })

  const isFiltering =
    filters?.str?.length ||
    filters?.endDate ||
    filters?.startDate ||
    filters?.filters?.filter((filter) => filter?.options?.some((filterItem) => filterItem?.checked))?.length

  const filteredItems = isFiltering
    ? applyFilters(allItems, filters)
    : allItems
        .filter((item) => item?.highlighted)
        ?.filter((item) => isToday(item?.date) || isAfter(item?.date, new Date()))

  const resetFilters = () => {
    setFilters(false)
    setFilterKey(`search-key_${parseInt(filterKey?.split('_')?.[1], 10) + 1}`)
    setFilterOptions(initialFilterOptions)
  }

  const filterSection = (
    <div>
      <div className="mb-6">
        <h4 className="lg:hidden mb-6">{intl.formatMessage({ id: 'agenda.filtersTitle' })}</h4>
        {filterOptions ? (
          <Filters
            key={filterKey}
            title={intl.formatMessage({ id: 'agenda.filtersTitle' })}
            {...{
              filters: filterOptions,
              onSearch: (str) => {
                setFilters({ ...filters, str })
              },
              onFilter: (newFilters) => {
                setFilters({ ...filters, ...newFilters })
              }
            }}
          />
        ) : null}
        <div className="mt-4">
          {isFiltering ? (
            <Button icon="Close" variant="tertiary" onClick={resetFilters}>
              {intl.formatMessage({ id: 'filter.resetFilters' })}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )

  const resultsSection = (
    <EventsList
      title={isFiltering ? fieldFilteredEventsTitle : fieldUpcomingEventsTitle}
      noResultsTitle={isFiltering ? fieldNoFilteredEventsTitle : fieldNoUpcomingEventTitle}
      events={filteredItems}
    />
  )

  const sidebarSection =
    fieldShowEventsOftheDay && !hasSidebar && todayEvents?.length ? (
      <TodayEvents title={fieldEventsOfTheDayTitle} events={todayEvents} />
    ) : null

  return <AgendaLayout search={filterSection} results={resultsSection} sidebar={sidebarSection} />
}

export default ParagraphAgenda
