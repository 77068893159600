import React from 'react'
//
import Menu from '~storybook/anchor-menu'

const ParagraphMenu = ({ fieldHeading, fieldHeadingType, fieldLink = [] }) => {
  //const links = fieldLink?.map((link) => file?.entity)

  return <Menu heading={fieldHeading} headingType={fieldHeadingType} links={fieldLink}/>
}

export default ParagraphMenu