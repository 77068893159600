import React from 'react'
import ReactHtmlParser from 'react-html-parser';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'

//import {MapControl, Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from "leaflet";
import "leaflet/dist/leaflet.css"
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

/*let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;*/

export const FedMap = ({
  uuid = '',
  markerContent = '',
  scrollwheelzoom = 'false',
  zoom = '19',
  mapStyle = 'day',
  geofield = {
    lat: '50.8461',
    lon: '4.3667'
  },
  markers = ''
}
) => {

  console.log(markers)
  console.log(uuid)

  const position = [geofield?.lat, geofield?.lon]
  const inlineStyle = {
    height: '500px',
    width: '100%'
  }

  if (typeof window !== 'undefined') {
    return (
        <Map 
          center={position} 
          zoom={zoom} 
          style={inlineStyle} 
          scrollWheelZoom={scrollwheelzoom} 
          key={`map-${uuid}`}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
          />
          {markers?.map((marker, idx) => (
            <>
            <Marker position={[marker?.entity?.fieldGeofield?.lat, marker?.entity?.fieldGeofield?.lon]} key={`marker-${idx}`}>
              <Popup>
                { ReactHtmlParser(marker?.entity?.fieldMapMarkerContent?.processed) }
              </Popup>
            </Marker>
          </>
          ))}
        </Map>
    );
  }

  return null
}

export default FedMap