/* eslint-disable no-unused-expressions */
import React, { useContext } from 'react'
import tw from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'
import { isBefore, isAfter, addDays, getUnixTime } from 'date-fns'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import { useIntl } from 'react-intl'
//
import { SiteDataContext } from '~components/layout/wrappers'
import Timeline from '~storybook/timeline'
import { CardSet } from '~storybook/cards'
import NewsfeedCard from '~storybook/newsfeed'
import { formatTZ } from '~utils/dates'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const fieldTypes = {
  event: 'events',
  news: 'news'
}

export const ParagraphView = ({
  language,
  id,
  fieldType,
  fieldShowAs,
  fieldBlacklist,
  fieldWhitelist,
  fieldFilterOnDate,
  fieldShowMaxItems,
  hasSidebar,
  isColumnRow = false
}) => {
  const siteData = useContext(SiteDataContext)
  const intl = useIntl()
  const { group, lang } = siteData

  const data = useStaticQuery(graphql`
    query NewsViewQuery {
      drupal {
        groupQuery(limit: 1000) {
          entities {
            ... on Drupal_GroupWebsite {
              id
              reverseGidGroupContent(
                limit: 10000
                filter: { conditions: { field: "type.target_id", value: "website-group_node-news" } }
              ) {
                entities {
                  __typename
                  ... on Drupal_GroupContentWebsiteGroupNodeNews {
                    queryEntityId {
                      entities {
                        entityTranslations {
                          ... on Drupal_NodeNews {
                            langcode {
                              value
                            }
                            title
                            nid
                            fieldType
                            fieldNewsSource {
                              entity {
                                tid
                                name
                              }
                            }
                            fieldTypeLabel
                            fieldEntityImage {
                              entity {
                                ...FragmentImage
                              }
                            }
                            fieldNewsDate {
                              date
                              value
                            }
                            fieldNewsTopics {
                              entity {
                                tid
                                name
                              }
                            }
                            fieldNewsSummary
                            fieldNewsLinkToSource
                            fieldNewsSourceLink {
                              uri
                              title
                            }
                            path {
                              alias
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const whitelistTopics = []
  const blacklistTopics = []

  if (fieldWhitelist) {
    fieldWhitelist?.forEach((entity) => {
      whitelistTopics.push(entity?.entity?.tid)
    })
  }

  if (fieldBlacklist) {
    fieldBlacklist?.forEach((entity) => {
      blacklistTopics.push(entity?.entity?.tid)
    })
  }

  const allNews = []
  const groupNews =
    data?.drupal?.groupQuery?.entities
      ?.find((entity) => entity.id === group.id)
      ?.reverseGidGroupContent?.entities?.map((entity) => entity?.queryEntityId?.entities)
      ?.map((entity) => entity?.[0]?.entityTranslations) || []

  groupNews.forEach((entities) => {
    const translation = entities?.find((entity) => entity?.langcode?.value === language)
    if (translation && translation?.fieldNewsDate?.date) {
      const { nid, path, title, fieldNewsSummary, fieldNewsDate, fieldTypeLabel, langcode, fieldNewsSourceLink } = translation

      const topics = translation?.fieldNewsTopics?.map((topic) => {
        return { ...topic.entity }
      })

      const sources = translation?.fieldNewsSource?.map((source) => {
        return { ...source.entity }
      })

      const image = translation?.fieldEntityImage?.entity?.thumbnail
      const timestamp = getUnixTime(new Date(translation?.fieldNewsDate?.value))
      const newsDate = new Date(translation?.fieldNewsDate?.value)
      const dateStr = newsDate && `${formatTZ(newsDate, 'd LLLL yyyy | HH:mm', { locale: localesObj?.[language] })}`

      if (fieldType === 'all' || fieldType === fieldTypes?.[translation?.fieldType]) {
        allNews.push({
          langcode: langcode.value,
          image,
          fieldNewsDate,
          date: dateStr,
          fieldTypeLabel,
          nid,
          path,
          title,
          summary: fieldNewsSummary,
          topics,
          sources,
          timestamp,
          url: translation?.path?.alias ? `/${lang}${translation?.path?.alias}` : `/${lang}/node/${translation?.nid}`,
          fieldNewsSourceLink
        })
      }
    }
  })

  const filteredNews = allNews.filter((entity) => {
    if (entity?.langcode !== language) {
      return false
    }
    if (
      fieldFilterOnDate?.startDate &&
      !isAfter(new Date(entity?.fieldNewsDate?.value), new Date(fieldFilterOnDate.value))
    ) {
      return false
    }
    if (
      fieldFilterOnDate?.endDate &&
      !isBefore(new Date(entity?.fieldNewsDate?.value), addDays(new Date(fieldFilterOnDate.endValue), 1))
    ) {
      return false
    }
    if (
      fieldWhitelist?.length &&
      (!entity.topics?.length || !entity.topics?.some((topic) => whitelistTopics.includes(topic.tid)))
    ) {
      return false
    }
    if (fieldBlacklist?.length && entity?.topics?.some((topic) => blacklistTopics.includes(topic.tid))) {
      return false
    }
    return true
  })

  /* eslint-disable no-nested-ternary */
  filteredNews.sort((a, b) => (a?.timestamp < b?.timestamp ? 1 : b?.timestamp < a?.timestamp ? -1 : 0))
  /* eslint-disable no-nested-ternary */

  const availableNews = filteredNews?.slice(0, fieldShowMaxItems || 18)

  if (fieldShowAs === 'timeline') {
    const cards = availableNews.map((entity) => {
      return {
        id: `news-timeline-${id}-${entity?.nid}`,
        title: entity?.date,
        cardTitle: entity?.title,
        cardText: entity?.summary,
        cta: {
          label: intl.formatMessage({ id: 'news.readMore' }),
          url:
            entity?.fieldNewsSourceLink?.uri ||
            entity?.url
        },
        image: {
          url: entity?.image?.url,
          width: entity?.image?.width,
          height: entity?.image?.height,
          credits: null
        }
      }
    })
    return <Timeline cards={cards} compact={hasSidebar} />
  }

  if (fieldShowAs === 'card_img_top') {
    return (
      <CardSet
        data-search-ignore="true"
        cols={hasSidebar || isColumnRow ? 2 : 3}
        cards={availableNews?.map((entity) => {
          return {
            type: 'card_image_text',
            id: `newscard-${id}-${entity?.nid}`,
            title: entity?.title,
            category: entity?.fieldTypeLabel,
            date: new Date(entity?.fieldNewsDate?.value),
            text: { value: entity?.summary },
            image: {
              url: entity?.image?.url
            },
            cta: {
              label: intl.formatMessage({ id: 'news.readMore' }),
              url: 
                entity?.fieldNewsSourceLink?.uri ||
                entity?.url
            }
          }
        })}
      />
    )
  }

  if (fieldShowAs === 'card_img_bg') {
    return (
      <CardSet
        data-search-ignore="true"
        cols={hasSidebar || isColumnRow ? 2 : 3}
        cards={availableNews?.map((entity) => {
          return {
            type: 'card_image_bg',
            id: `newscard-${id}-${entity?.nid}`,
            title: entity?.title,
            category: entity?.fieldTypeLabel,
            date: new Date(entity?.fieldNewsDate?.value),
            image: {
              url: entity?.image?.url
            },
            cta: {
              label: intl.formatMessage({ id: 'news.readMore' }),
              url: 
                entity?.fieldNewsSourceLink?.uri ||
                entity?.url
            }
          }
        })}
      />
    )
  }

  return (
    <div className="mb-4" data-search-ignore="true">
      {availableNews?.map((entity, idx) => (
        <div key={`news-list-${id}-${entity?.nid}`} css={[tw`mb-2`]}>
          <div css={[tw`pb-4`]}>
            <NewsfeedCard
              mode="list"
              title={entity?.title}
              date={new Date(entity?.fieldNewsDate?.value)}
              category={entity?.fieldTypeLabel}
              cta={{
                url:
                  entity?.fieldNewsSourceLink?.uri ||
                  entity?.url
              }}
            />
          </div>
          {idx < availableNews.length - 1 ? <hr className="mb-4" /> : null}
        </div>
      ))}
    </div>
  )
}

export default ParagraphView
