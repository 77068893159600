import React from 'react'
//
import prettyBytes from 'pretty-bytes'
import { Heading } from '~storybook/blocks'
import NavLink from '~utils/navlink'

const Downloads = ({ title, files = [] }) => (
  <div>
    <Heading level="h2" text={title} />
    <ul className="my-8">
      {files?.map((file, idx) => (
        <li key={`file-${file?.entity?.fid}-${idx}`} className="my-1 py-1">
          <NavLink to={file?.entity?.uri.value.replace('public://', process.env.DRUPAL_MEDIA_HOSTNAME)}>
            {file?.description || file?.entity?.filename}
          </NavLink>{' '}
          <span className="text-sm">({prettyBytes(file?.entity?.filesize, { locale: 'be' })})</span>
        </li>
      ))}
    </ul>
  </div>
)

export default Downloads
