import React from 'react'
import parse from 'style-to-object';
//
import Breadcrumb from '~storybook/breadcrumb'

const ParagraphBreadcrumb = ({ fieldHeading, fieldHeadingType, fieldMenu, fieldLink = [], fieldCss }) => {
  const CssFromContent = parse(fieldCss)
  return <Breadcrumb heading={fieldHeading} headingType={fieldHeadingType} links={fieldLink} menu={fieldMenu} style={CssFromContent} />
}

export default ParagraphBreadcrumb
