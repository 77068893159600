import React, { useRef, useState } from 'react'
import tw, { theme, styled } from 'twin.macro'
import { Swiper, SwiperSlide } from 'swiper/react'
//
import { Heading } from '~storybook/blocks/typography/index'
import { ImageProxy } from '~storybook/image/image'
import { Html } from '~storybook/blocks/html/html'
import Button from '~storybook/button/index'
import 'swiper/swiper-bundle.css'
import Icons from '~storybook/icons/index'
import FormattedText from '~utils/text'

const BannerWrapper = styled.div`
  ${tw`w-full flex flex-col`}
  ${({ orientation }) => (orientation === 'right' ? tw`lg:flex-row-reverse` : tw`lg:flex-row`)}
  ${({ dark }) => (dark ? tw`bg-c-banner-wrapper-dark` : tw`bg-c-banner-wrapper-light`)}

  min-height: 480px;
  max-height: 480px;

  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    min-height: 822px;
  }

  @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
    min-height: 844px;
  }
`

const BannerCarouselWrapper = styled.div`
  ${tw`relative inline-block`}

  width: 100%;
  height: 480px;
  min-height: 480px;
  max-height: 480px;

  &,
  img,
  .gatsby-image-wrapper {
    min-height: 480px;
    max-height: 480px;
  }

  .slide-contents {
    background: rgba(0, 0, 0, 0.4);
    ${tw`text-white w-full h-full absolute top-0 left-0 text-center flex flex-col justify-center`}
    ${tw``}

    h1 {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }
  }

  .slider-controls {
    ${tw`flex-row flex relative container max-w-7xl content-center justify-between mx-auto px-6 md:px-10`}
    ${tw`text-white`}
    width: 100%;
    z-index: 1;
    margin-top: -72px;

    button {
      ${tw`outline-none`}
    }

    svg {
      width: 32px;
      height: 32px;
    }

    .slider-dots {
      margin: 10px auto;
      .dot {
        width: 10px;
        height: 10px;
        margin: 0px 3px;
        display: block;
        background: ${theme`colors['c-hero-banner'].bg`};
        border-radius: 50%;
        border: solid 2px ${theme`colors['c-hero-banner'].sliderDots`};

        &.active {
          background: ${theme`colors['c-hero-banner'].activeSliderDots`};
        }
      }
    }

    .slider-prev,
    .slider-next {
      ${tw`hidden lg:inline`}
    }
  }
`

const ContentWrapper = styled.div`
  @media (min-width: ${theme`screens.lg`}) {
    ${tw`lg:flex relative container max-w-7xl content-center justify-between mx-auto px-6 md:px-10`}
    ${({ orientation }) => (orientation === 'right' ? tw`lg:flex-row` : tw`lg:flex-row-reverse`)}
    & {
      height: 480px;
    }
    & > div {
      height: 480px;
      width: 632px;
    }
  }
`
const ImageWrapper = styled.div`
  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    width: 100%;
  }

  @media (min-width: ${theme`screens.lg`}) {
    ${tw`absolute`}
    width: calc(50% + 176px);
    height: 480px;
  }

  &,
  img {
    height: 480px;
  }
`

const HeroBanner = ({
  orientation = 'left',
  dark = false,
  image = {},
  title = '',
  text = '',
  cta = {},
  socialLinks = null,
  items = []
}) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const ref = useRef(null)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(activeSlide + 1)
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(activeSlide - 1)
    }
  }

  const goTo = (index) => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(index)
    }
  }

  const params = {
    onSlideChange: (swiper) => {
      setActiveSlide(swiper?.activeIndex)
    }
  }

  const cardBanner = (
    <BannerWrapper orientation={orientation} dark={dark}>
      <ImageWrapper>
        {image && image?.url && (
          <ImageProxy
            className="absolute w-full h-full top-0 left-0"
            objectFit="cover"
            {...{
              url: image?.url,
              type: 'fluid',
              params: {
                mw: 808,
                mh: 480,
                g: 'sm',
                r: 'fit'
              }
            }}
          />
        )}
      </ImageWrapper>
      <ContentWrapper orientation={orientation}>
        <div
          className={`${
            dark
              ? 'bg-c-hero-banner-card-darkBg text-c-hero-banner-card-darkText'
              : 'bg-c-hero-banner-card-lightBg text-c-hero-banner-card-lightText'
          } flex flex-col w-full px-6 md:px-10 lg:px-20 pb-6 md:pb-14 lg:pb-10 pt-8 md:pt-10 lg:pt-20 justify-between`}
        >
          <div>
            {title && (
              <Heading
                className={`${
                  dark ? 'text-c-hero-banner-card-titleDarkText' : 'text-c-hero-banner-card-titleLightText'
                } mb-5 font-medium`}
                level="h1"
                text={title}
              />
            )}
            {text && (
              <div className="flex flex-row text-c-hero-banner-card-text mb-8">
                <div
                  className={`${
                    dark ? 'text-c-hero-banner-card-textDarkText' : 'text-c-hero-banner-card-textLightText'
                  } text-xl font-base`}
                >
                  <Html value={text} />
                </div>
              </div>
            )}
            <div className="flex flex-col md:flex-row flex-grow-0">
              <div>
                {cta?.url && cta?.label && (
                  <Button variant={dark ? 'dark' : undefined} to={cta?.url}>
                    {cta?.label}
                  </Button>
                )}
              </div>
              {socialLinks}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  )

  const carouselBanner =
    Array.isArray(items) && items.length > 0 ? (
      <BannerCarouselWrapper>
        <Swiper {...params} ref={ref}>
          {items.map((item, i) => {
            return (
              <SwiperSlide key={`slider_slide_${i}`}>
                <ImageProxy
                  className="absolute w-full h-full top-0 left-0"
                  objectFit="cover"
                  {...{
                    url: item?.image?.url,
                    type: 'fluid',
                    params: {
                      mw: 1920,
                      mh: 480,
                      g: 'sm',
                      r: 'fill'
                    }
                  }}
                />
                {item?.title || (item?.cta?.url && item?.cta?.label) ? (
                  <div className="slide-contents">
                    <div class="title_body">
                      {item?.title && <Heading className="text-white mb-5 font-medium" level="h1" text={item?.title} />}
                      <footer>
                        {item?.body && 
                          <FormattedText text={item?.body?.value} format={item?.body?.format || 'html'} />
                        }
                        {item?.cta?.url && item?.cta?.label && (
                          <Button variant="dark" to={item?.cta?.url}>
                            {item?.cta?.label}
                          </Button>
                        )}
                      </footer>
                    </div>
                  </div>
                ) : null}
              </SwiperSlide>
            )
          })}
        </Swiper>
        {Array.isArray(items) && items?.length > 1 && (
          <div className="slider-controls">
            <button type="button" className="slider-prev" onClick={goPrev}>
              <Icons.ArrowLeft />
            </button>
            <div className="slider-dots flex flex-row">
              {items.map((item, i) => {
                return (
                  <button
                    type="button"
                    key={`slider_dot_${i}`}
                    className={i === activeSlide ? 'active dot' : 'dot'}
                    onClick={() => goTo(i)}
                  >
                    <span />
                  </button>
                )
              })}
            </div>
            <button type="button" className="slider-next" onClick={goNext}>
              <Icons.ArrowRight />
            </button>
          </div>
        )}
      </BannerCarouselWrapper>
    ) : null

  return Array.isArray(items) && items.length > 0 ? carouselBanner : cardBanner
}

export default HeroBanner
