import React from 'react'
//
import { Heading } from '~storybook/blocks'
import NavLink from '~utils/navlink'

const Breadcrumb = ({ heading, headingType, links = [], style }) => (
  
  <section className="breadcrumb" style={style}>
    <nav aria-label="breadcrumbs">
      <Heading level={headingType} text={heading} />
      <ol className="m-0 inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        {links?.map((link, idx) => (
          <li key={`link-${idx}`} className="p-0 inline-flex items-center m-0">
            <NavLink to={link?.url?.path} className="inline-flex items-center text-sm font-medium no-underline text-center hyphens-none">
              {link?.title || link?.url?.path}
            </NavLink>{' '}
          </li>
        ))}
      </ol>
    </nav>
  </section>
)

export default Breadcrumb
