import React from 'react'
import tw, { theme, styled } from 'twin.macro'
//
import Heading from '~storybook/blocks/typography/heading'
import Button from '~storybook/button/index'
import { ImageProxy } from '~storybook/image/image'
import FormattedText from '~utils/text'

const ImageContentWrapper = styled(({ orientation, ...other }) => <div {...other} />)`
  ${tw`lg:grid lg:grid-cols-2 lg:gap-8`}
  ${({ orientation }) => (orientation === 'left' ? tw`flex flex-col` : tw`flex flex-col flex-col-reverse`)}
`

const ImageCredits = styled.div`
  ${tw`absolute bottom-0 right-0 text-white py-2 px-3 text-sm z-10`}
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${theme`colors['c-image-content-section'].bg`};
    opacity: 1;
    z-index: -1;
  }
`

const imageParams = {
  mw: 1170,
  mh: 740,
  g: 'sm',
  r: 'fit'
}

const onlyImageParams = {
  mw: 1170,
  mh: 540,
  g: 'sm',
  r: 'fit'
}

const ImageContent = ({ image, text, cta, orientation, noCrop = false, id = '' }) => {
  const { caption, credits } = image

  const imageOnly = !text || (!text?.title && !text?.subtitle && !text?.content)

  const imageBlock = (
    <figure>
      <div className="relative block">
        <ImageProxy
          url={image?.url}
          width={image?.width}
          height={image?.height}
          type="fluid"
          params={imageOnly ? onlyImageParams : imageParams}
          noCrop={noCrop}
        />
        {credits && <ImageCredits>{credits}</ImageCredits>}
      </div>
      {caption && <figcaption className="mt-4 mb-6 text-c-image-content-section-text">{caption}</figcaption>}
    </figure>
  )

  const textBlock = (
    <div>
      {text?.title && <Heading className="mb-4" level="h2" text={text?.title} /*id={id}*/ />}
      {text?.subtitle && <Heading className="mb-4 font-thin" level="h4" text={text?.subtitle} />}
      {text?.content && (
        <div className="mb-6">
          <FormattedText text={text?.content} format={text?.format} />
        </div>
      )}
      {cta?.link && cta?.label && <Button to={cta?.link}>{cta?.label}</Button>}
    </div>
  )

  return imageOnly ? (
    imageBlock
  ) : (
    <ImageContentWrapper orientation={orientation} id={id}>
      {orientation === 'left' ? (
        <>
          <>{imageBlock}</>
          <>{textBlock}</>
        </>
      ) : (
        <>
          <>{textBlock}</>
          <>{imageBlock}</>
        </>
      )}
    </ImageContentWrapper>
  )
}

export default ImageContent
