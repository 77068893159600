import React from 'react'
//
import { Heading } from '~storybook/blocks'
import NavLink from '~utils/navlink'

const Menu = ({ heading, headingType, links = [] }) => (
  <nav className="component_menu">
    {heading &&
      <><Heading level={headingType} text={heading} /></>
    }
    <ul className="my-8">
      {links?.map((link, idx) => (
        <li key={`link-${idx}`} className="py-1">
          <NavLink to={link?.url?.path}>
            {link?.title || link?.url?.path}
          </NavLink>{' '}
        </li>
      ))}
    </ul>
  </nav>
)

export default Menu
