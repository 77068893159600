
import Menu from '~storybook/menu'







import React, { useEffect, useLayoutEffect, useState, useRef, createRef } from "react";

//
import NavLink from '~utils/navlink'
import {Helmet} from "react-helmet"


export const ParagraphMenu = ({fieldHeading, fieldHeadingType, fieldMenu, fieldLink = [], componentMenus = [], language = 'NL' }) => {

  console.log(fieldLink.length)

  // if fieldlink is filled, use the old menu.
  if(fieldLink.length > 0) {
    return <Menu heading={fieldHeading} headingType={fieldHeadingType} links={fieldLink} menu={fieldMenu} />
  }


  const containerRef = useRef(null)
  const menuElement = useRef([])
  const controllerElement = useRef(null)
  const externalLibraryRef = useRef(null)


  // Create a ref containing all menu items for the <ul> (sub)menus
  // See https://stackoverflow.com/questions/65350114/useref-for-element-in-loop-in-react
  //menuElement.current = componentMenus[fieldMenu.targetId][language].map((_, i) => menuElement.current[i] ?? createRef());
  
  const random = (Math.random() + 1).toString(36).substring(7)

  useEffect(() => {
    externalLibraryRef.current = new AccessibleMenu.DisclosureMenu({
      menuElement: menuElement.current,
      submenuItemSelector: ".dropdown",
      containerElement: containerRef.current,
      controllerElement: controllerElement.current,
      optionalKeySupport: true,
      hoverType: "off", // "on", "off"
    });

    // Use the external library instance
    //externalLibraryRef.current.init();
    //externalLibraryRef.current.someMethod();
  }, []);

  const menuItemRendered = (item) => {
    const hasSubItems = Array.isArray(item?.below) && item?.below?.length > 0
    const subItems = item?.below?.length > 0 ? item.below : []
    
    return (
      <>
       {hasSubItems ? (
        <>
          <li key={item.key} className="menu-item dropdown ">
            <NavLink key={item.key} to={item.uri}>{item.title}</NavLink>
            <button class="dropdown-toggle" aria-label="{item.title} submenu"></button>

               <ul class="bg-white">
                  {subItems.map((subItem, id) => (
                    <>{menuItemRendered(subItem)}</>
                  ))}
                </ul>
              

            
          </li>
        </>
        ) :  <><li key={item.key} className="menu-item"><NavLink key={item.key} to={item.uri}>{item.title}</NavLink></li></>
      }
      </>
    )
  }


  return (
    <>
      <Helmet>
        
        <link type="text/css" rel="stylesheet" href="/css/accessible-menu.css" />
      </Helmet>
      <nav ref={containerRef} className="disclosure-menu top-link-disclosure-menu">
        <button class="menu-toggle" aria-label="Menu" ref={controllerElement}><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg> Menu</button>
        <ul ref={menuElement} className="menu">
        
        {
          componentMenus[fieldMenu.targetId][language].map((item, idx) => {
            const menuItem = menuItemRendered(item)

            return (
              <>
                {menuItem}
              </>
            )
          })
        }
      </ul></nav>
    </>
  )

}

export default ParagraphMenu