import React from 'react'
import { IntlContext, FormattedMessage } from 'react-intl'
import sanitize from 'sanitize-filename'
//
import { Form } from '~storybook/blocks'
import { isSSR } from '~utils/ssr'

const filenameSanitizer = (name) => {
  // eslint-disable-next-line no-control-regex
  return sanitize(name).replace(/[^\x00-\x7F]/g, '')
}

export const ParagraphForm = ({ entityRendered, parentId, fieldForm, fieldMaximumUploadSize }) => {
  const intl = React.useContext(IntlContext)
  const ref = React.useRef()
  const locale = intl?.locale

  if (!entityRendered || !fieldForm?.entity?.entityId) {
    return null
  }

  const { entityId } = fieldForm.entity

  const [data, setData] = React.useState({ errors: null, values: null, isSubmitted: false })

  const handleUpload = async (field, files) => {
    const tokenUrl = `${process.env.DRUPAL_FORMS_URL}/rest/session/token`
    const submitUrl = `${process.env.DRUPAL_FORMS_URL}/${locale}/webform_rest/${entityId}/upload/${field}?_format=json`
    const token = await fetch(tokenUrl).then((response) => response.text())

    // eslint-disable-next-line no-unused-expressions
    const fids = await Promise.all(
      files?.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader()

          reader.onload = async () => {
            const binaryStr = reader.result

            const submission = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/octet-stream',
                'Content-Disposition': `file; filename="${filenameSanitizer(file.name)}"`,
                'X-CSRF-Token': token
              },
              redirect: 'follow',
              body: binaryStr
            }
            const res = await fetch(submitUrl, submission)
              .then((response) => response.json())
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e)
              })

            resolve(res)
          }

          reader.readAsArrayBuffer(file)
        })
      })
    )

    return fids
  }

  const handleSubmission = async (obj, formData) => {
    const tokenUrl = `${process.env.DRUPAL_FORMS_URL}/rest/session/token`
    const submitUrl = `${process.env.DRUPAL_FORMS_URL}/${locale}/webform_rest/submit`
    const token = await fetch(tokenUrl).then((response) => response.text())

    const submission = {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      body: JSON.stringify({
        token,
        webform_id: entityId,
        entity_type: 'node',
        entity_id: parentId,
        ...obj
      })
    }

    const result = await fetch(submitUrl, submission)
      .then((response) => response.json())
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })

    if (result?.error) {
      setData({ errors: { error: result?.error }, message: result?.message, values: formData, isSubmitted: false })
    } else if (result?.sid) {
      setData({ errors: null, values: formData, isSubmitted: true })
      if (!isSSR) {
        setTimeout(() => {
          const section = ref.current.getBoundingClientRect()
          window.scrollTo({
            top: section.top + window.scrollY - 50,
            behavior: 'smooth'
          })
        }, 200)
      }
    } else {
      setData({ errors: { message: 'Unknown error!', error: {} }, values: formData, isSubmitted: false })
    }
  }

  const { errors, values, isSubmitted } = data

  return isSubmitted ? (
    <section ref={ref} className="bg-white-500 w-full mb-8 relative">
      <h3 className="p-6 text-green-800">
        <FormattedMessage id="form.success" />
      </h3>
    </section>
  ) : (
    <section className="bg-white-500 w-full mb-8 relative">
      <Form
        errors={errors}
        values={values}
        entityRendered={entityRendered}
        fieldForm={fieldForm}
        onSubmit={handleSubmission}
        onUpload={handleUpload}
        maxUploadSize={fieldMaximumUploadSize ? fieldMaximumUploadSize * 1024 * 1024 : 20 * 1024 * 1024}
      />
    </section>
  )
}

export default ParagraphForm
