import React from 'react'
import { FedMap } from '~storybook/cards'

export const ParagraphMap = ({
  uuid,
  fieldAddress,
  fieldMarkerContent,
  fieldScrollwheelzoom,
  fieldZoom,
  fieldGeofield,
  fieldMapStyle,
  fieldMapMarkers
}) => {

  return (
    <FedMap uuid={uuid} address={fieldAddress} markerContent={fieldMarkerContent} scrollwheelzoom={fieldScrollwheelzoom} zoom={fieldZoom} geofield={fieldGeofield} mapStyle={fieldMapStyle} markers={fieldMapMarkers} />
  )
}

export default ParagraphMap
