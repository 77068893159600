import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import { FormattedMessage } from 'react-intl'
//
import Icons from '~storybook/icons/index'
import NavLink from '~utils/navlink'

const TertiaryStyle = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`text-c-newsfeed-card-secondary font-medium py-0 mb-6 mt-4 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent hover:text-c-newsfeed-card-primary hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 justify-start`}

  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const StyledListItem = styled.li`
  a {
    &:hover {
      color: ${theme`colors['c-agenda-results'].fg`} !important;
    }
  }
`

export const TodayEvents = ({ title, events = [] }) => (
  <div className="text-c-agenda-today-fg w-full">
    <div className="p-4 bg-c-agenda-today-bg">
      <h4 className="mb-8 text-xl">{title}</h4>
      <ul className="list-none p-0 m-0">
        {events?.map((event) => (
          <li key={`today-${event?.id}`} className="flex p-0 mb-6 text-sm">
            <div className="pr-4">
              <Icons.Time />
            </div>
            <div className="flex-row">
              <div className="font-bold">{event?.timeStr}</div>
              <div>{event?.title}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export const EventsList = ({ title, noResultsTitle, events = [] }) => (
  <div>
    <h3 className="text-3xl text-c-agenda-results-fg mb-8">{events?.length ? title : noResultsTitle}</h3>
    <ul className="list-none p-0 m-0 text-c-agenda-results-fg">
      {events?.map((event, idx) => (
        <StyledListItem key={`agenda-result-${event?.id}`} className="mb-6">
          <NavLink
            to={event?.url}
            className="no-underline font-normal text-c-agenda-results-fg hover:text-c-agenda-results-fg"
          >
            <div>
              <h3>{event.title}</h3>
              <div className="flex my-2 items-center">
                {event?.location ? (
                  <>
                    <div>
                      <Icons.LocationPin />
                    </div>
                    <div className="mr-4 ml-2">{event?.location}</div>
                  </>
                ) : null}
                {event?.dateStr ? (
                  <>
                    <div>
                      <Icons.Calendar />
                    </div>
                    <div className="ml-2">{event.dateStr}</div>
                  </>
                ) : null}
              </div>
              <p className="my-4 pb-2">{event?.summary}</p>
            </div>
          </NavLink>
          <TertiaryStyle to={event?.url} hasIcon>
            <div className="block">
              <FormattedMessage id="news.readMore" />
              <Icons.ArrowRight />
            </div>
          </TertiaryStyle>
          {idx < events.length - 1 ? <hr className="pb-4" /> : null}
        </StyledListItem>
      ))}
    </ul>
  </div>
)
