import React, { useEffect, useRef, useState } from 'react'
import { Paragraph } from '~storybook/blocks/typography/index'
import useWindowDimensions from '~utils/responsive'
//
import { isSSR } from '~utils/ssr'

const Video = ({ id, description }) => {
  if (!id) {
    return null
  }

  const ref = useRef()
  const { width } = isSSR ? { width: 960 } : useWindowDimensions()
  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)

  useEffect(() => {
    if (ref.current) {
      setVideoWidth(ref.current.clientWidth)
      setVideoHeight(ref.current.clientWidth / 1.78)
    }
  }, [width, ref])

  // check if link is self hosted video file
  let regex = new RegExp(/[^\s]+(.*?).(mp4|ogg)$/);
  if (regex.test(id) == true) {
    return (
      <figure id="videoContainer">
      <video id="video" controls preload="metadata">
        <source
          src={`${id}`}
          type="video/mp4" />
      </video>
      {description ? <Paragraph className="mt-4"><figcaption>{description}</figcaption></Paragraph> : null}
    </figure>
    )
  }

  return (
    <div ref={ref}>
      <iframe
        title="video"
        width={videoWidth}
        height={videoHeight}
        src={`https://www.youtube-nocookie.com/embed/${id}?modestbranding=1&rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      {description ? <Paragraph className="mt-4">{description}</Paragraph> : null}
    </div>
  )
}

export default Video
