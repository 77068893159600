import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import { IntlContext, useIntl } from 'react-intl'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
//
import { TwitterLike, TwitterVerified } from '~images/icons/icons'
import Icons from '~storybook/icons/index'
import { Disclaimer, Heading, Label } from '~storybook/blocks/typography/index'
import { ImageProxy } from '~storybook/image/image'
import FormattedText from '~utils/text'
import NavLink from '~utils/navlink'
import { formatTZ } from '~utils/dates'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const LinkWrapper = styled.div`
  ${tw`relative flex flex-col border border-c-twitter-border rounded mt-1 mb-6`}
  z-index: 1;
  &,
  .gatsby-image-wrapper {
    width: 262px;
  }

  * {
    ${tw`no-underline cursor-pointer`}
  }
`

const ImageWrapper = styled.div`
  ${tw`relative mt-1 mb-6`}
  &, .gatsby-image-wrapper {
    width: 203px;
  }
`
const TweetWrapper = styled.div`
  ${tw`relative flex flex-col border border-c-twitter-border px-5 py-5 rounded text-c-twitter-text hover:text-c-twitter-text text-base font-normal`}
  @media (min-width: ${theme`screens.lg`}) {
    max-width: 332px;
  }

  @media (min-width: ${theme`screens.md`}) and (max-width: ${`${
      parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1
    }px`}) {
    min-width: 250px;
  }

  a {
    ${tw`no-underline cursor-pointer`}
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
    display: block;
  }

  .header {
    div {
      z-index: 1;
    }

    .tweet-icon {
      margin-top: -4px;
    }
  }
  .header svg {
    width: 17px;
    height: 17px;

    path {
      fill: ${theme`colors['c-twitter'].headerFill`};
    }
    z-index: 1;
  }

  .tweet-content {
    z-index: 1;
  }

  .footer {
    z-index: 1;
  }

  .footer svg {
    width: 17px;
    height: 17px;

    path {
      fill: ${theme`colors['c-twitter'].footerFill`};
    }
  }
`

export const Twitter = ({ items, title }) => {
  const intl = React.useContext(IntlContext)

  return (
    <div className="twitter-timeline">
      {title && (
        <Heading level="h3" className="mb-4 font-medium">
          {title}
        </Heading>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:grid-cols-1">
        {Array.isArray(items) &&
          items?.map((item, idx) => {
            const intl2 = useIntl()
            const tweetDate = new Date(item?.date)
            const dateStr =
              tweetDate && `${formatTZ(tweetDate, 'd LLLL yyyy · HH:mm', { locale: localesObj?.[intl2?.locale] })}`

            return (
              <TweetWrapper key={`tweet-${item?.id}-${idx}`} data-search-ignore="true">
                <NavLink className="overlay" to={item?.tweetUrl} />
                <div className="header flex flex-row justify-between items-start">
                  <NavLink to={item?.authorUrl || item?.tweetUrl} className="flex flex-row">
                    <div>
                      <ImageProxy
                        url={item?.profilePicture}
                        type="fixed"
                        className="rounded-full"
                        params={{
                          w: 36,
                          h: 36,
                          r: 'fit'
                        }}
                      />
                    </div>
                    <div className="flex flex-col justify-start ml-3">
                      <h6 className="m-0 p-0 leading-tight text-c-twitter-text hover:text-c-twitter-text no-underline">
                        {item?.author}
                      </h6>
                      <Label className="m-0 p-0 leading-tight text-c-twitter-textLabel hover:text-c-twitter-textLabel no-underline">
                        {item?.handler}
                      </Label>
                    </div>
                    <div className="flex flex-col justify-start ml-3">
                      <TwitterVerified />
                    </div>
                  </NavLink>
                  <div className="tweet-icon">
                    <NavLink to={item?.tweetUrl || item?.tweetUrl}>
                      <Icons.Twitter />
                    </NavLink>
                  </div>
                </div>

                <div className="tweet-content my-2">
                  <div className="text-c-twitter-text font-thin">
                    <FormattedText text={item?.text} format="html" />
                  </div>
                  <Label className="m-0 p-0 leading-tight text-c-twitter-textLabel">{dateStr}</Label>
                </div>

                {item?.link && item?.link?.image && (item?.link?.title || item?.link?.label || item?.link?.text) && (
                  <NavLink to={item?.tweetUrl}>
                    <LinkWrapper>
                      <ImageProxy
                        url={item?.link?.image}
                        type="fixed"
                        params={{
                          w: 262,
                          h: 148,
                          r: 'fit'
                        }}
                      />
                      <div className="px-3 py-3 flex flex-col text-c-twitter-text font-thin">
                        <Disclaimer className="font-medium mb-1 no-underline">{item?.link?.title}</Disclaimer>
                        <Disclaimer className="mb-1 no-underline">{item?.link?.text}</Disclaimer>
                        <Disclaimer className="text-c-twitter-textLabel no-underline">{item?.link?.label}</Disclaimer>
                      </div>
                    </LinkWrapper>
                  </NavLink>
                )}

                {item?.link && item?.link?.image && !(item?.link?.title || item?.link?.label || item?.link?.text) && (
                  <NavLink to={item?.tweetUrl}>
                    <ImageWrapper>
                      <ImageProxy
                        url={item?.link?.image}
                        type="fixed"
                        params={{
                          w: 203,
                          h: 107,
                          r: 'fit'
                        }}
                      />
                    </ImageWrapper>
                  </NavLink>
                )}

                <div className="footer mt-0 flex flex-row">
                  <div>
                    <NavLink className="flex flex-row" to={item?.likeUrl || item?.tweetUrl}>
                      <TwitterLike />
                      <Label className="ml-1 m-0 p-0 leading-tight text-c-twitter-textLabel">
                        {intl?.formatMessage({ id: 'twitter.likesText' }, { total: item?.likeCount })}
                      </Label>
                    </NavLink>
                  </div>
                </div>
              </TweetWrapper>
            )
          })}
      </div>
    </div>
  )
}

export default Twitter
