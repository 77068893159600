import React from 'react'
import { IntlContext } from 'react-intl'
//
import Twitter from '~storybook/twitter'

// eslint-disable-next-line no-useless-escape
const urlPattern = /\b(?:https?):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim

const ParagraphTwitterTimeline = ({ fieldTwitterTimeline, fieldMaxTweets, fieldOffset }) => {
  if (!fieldTwitterTimeline?.entity?.reverseFieldTwitterTimelineNode?.entities) {
    return null
  }

  const intl = React.useContext(IntlContext)

  const tweets =
    fieldTwitterTimeline?.entity?.reverseFieldTwitterTimelineNode?.entities?.map((entity) => {
      return {
        id: entity?.fieldTweetId,
        author: entity?.fieldTweetUserName,
        authorUrl: entity?.fieldTweetUserUrl?.url?.path,
        tweetUrl: entity?.fieldFieldTweetUrl?.url?.path,
        handler: `@${entity?.fieldTweetUserScreenName}`,
        profilePicture: entity?.fieldTweetUserProfileImg?.url,
        text: entity?.fieldTweetText.replace(urlPattern, '<a href="$&">$&</a>'),
        date: entity?.fieldFieldTweetDate?.value,
        likeCount: entity?.fieldTweetLikeCount,
        commentCount: entity?.fieldTweetCommentsCount,
        link: {
          image: entity?.fieldTweetImage?.url
        }
      }
    }) || []

  if (!tweets?.length) {
    return null
  }

  const author = tweets?.[0]?.handler

  const offset = (fieldOffset || 0) - 1 // minus 1, as the field offset starts at 1, not zero.
  const maxTweets = fieldMaxTweets || 100
  const maxTweetsOffset = maxTweets + offset

  return (
    <div className="mb-8">
      <Twitter
        title={intl.formatMessage({ id: 'twitter.title' }, { author })}
        items={tweets.slice(offset, maxTweetsOffset)}
      />
    </div>
  )
}

export default ParagraphTwitterTimeline
