import React from 'react'
import parse from 'date-fns/parse'
//
import Icons from '~storybook/icons/index'
import Card from '~storybook/cards'

const validIcons = {
  exclamationmark: <Icons.InfoOutline />,
  calendar: <Icons.Calendar />,
  textballoon: <Icons.CC />,
  telephone: <Icons.Telephone />
}

export const ParagraphCardIcon = ({
  fieldCardDate,
  fieldCardCallToAction,
  fieldCardIcon,
  fieldCardType,
  fieldCardTitle,
  fieldCardLinks,
  fieldCardDescription,
  fieldCssClass
}) => {
  const cta = {
    label: fieldCardCallToAction?.title,
    url: fieldCardCallToAction?.url?.path
  }

  const date = fieldCardDate?.value ? parse(fieldCardDate?.value, 'yyyy-MM-dd', new Date()) : null

  const icon = validIcons[fieldCardIcon] || null

  const links =
    fieldCardLinks?.map((link) => {
      return {
        label: link?.title,
        url: link?.url?.path,
        external: link?.url?.__typename === 'Drupal_ExternalUrl'
      }
    }) || []

  return (
    <section className="bg-white-500 w-full mb-8 relative card_icon">
      <Card
        type="card_icon_text"
        icon={icon}
        category={fieldCardType}
        date={date}
        title={fieldCardTitle}
        cta={cta}
        links={links}
        text={fieldCardDescription}
        cssClass={fieldCssClass}
      />
    </section>
  )
}

export default ParagraphCardIcon
