import React, { useRef, useState } from 'react'
import tw, { theme, styled } from 'twin.macro'
import { Swiper, SwiperSlide } from 'swiper/react'
//
import { ImageProxy } from '~storybook/image/image'
import 'swiper/swiper-bundle.css'
import Icons from '~storybook/icons/index'
import GalleryImage from './gallery-image'

const GalleryWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  max-width: ${({ compact }) => (compact ? '640px' : '790px')};
  min-height: ${({ compact }) => (compact ? '474px' : '585px')};

  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    width: 100%;
    min-height: ${({ compact }) => (compact ? '404px' : '515px')};
  }

  @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
    width: 100%;
    min-height: ${({ compact }) => (compact ? '221px' : '515px')};
  }

  .slider-thumbs {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    scrollbar-height: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    height: 80px;
    max-height: 80px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      max-height: 68px;
    }
    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      max-height: 64px;
    }

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }

    width: 100%;

    button {
      ${tw`outline-none focus:outline-none`}
      & {
        width: 72px;
        height: 72px;
      }
      .gatsby-image-wrapper {
        width: 72px;
        height: 72px;
      }
      margin-right: 8px;

      &.active {
        & {
          width: 68px;
          height: 68px;
        }
        .gatsby-image-wrapper {
          width: 67px !important;
          height: 67px !important;
        }
        .gatsby-image-wrapper {
          border: solid 3px ${theme`colors['c-gallery'].bgImg`};
        }
        margin-top: 3px;
        margin-right: 10px;
      }

      @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
        & {
          width: 63px;
          height: 63px;
        }
        .gatsby-image-wrapper {
          width: 60px !important;
          height: 60px !important;
        }
        margin-right: 10px;

        &.active {
          & {
            width: 59px;
            height: 60px;
          }
          .gatsby-image-wrapper {
            width: 56px !important;
            height: 57px !important;
          }
          margin-top: 1px;
          margin-right: 13px;
        }
      }

      @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
        &,
        &.active {
          width: 60px;
          height: 60px;
          margin-top: 0px;
          margin-right: 10px;
        }
        .gatsby-image-wrapper,
        &.active .gatsby-image-wrapper {
          width: 57px !important;
          height: 57px !important;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const GalleryButton = styled.button`
  ${tw`outline-none focus:outline-none mb-20`}

  svg {
    width: 48px;
    height: 48px;
    display: block;
  }
`

const Gallery = ({ items = [], compact = false }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const ref = useRef(null)
  const refThumbs = useRef(null)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(activeSlide + 1)
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(activeSlide - 1)
    }
  }

  const goTo = (index) => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(index)
    }
  }

  const params = {
    onSlideChange: (swiper) => {
      setActiveSlide(swiper?.activeIndex)
      const scrollableArea = refThumbs.current.scrollWidth - refThumbs.current.clientWidth + 100

      if (scrollableArea > 0) {
        refThumbs.current.scrollLeft = (swiper?.activeIndex / items.length) * scrollableArea
      }
    }
  }

  return (
    <div className="lg:flex lg:flex-row lg:justify-center">
      <GalleryButton className="hidden lg:inline mr-14" type="button" onClick={goPrev}>
        <Icons.ArrowLeft />
      </GalleryButton>
      <GalleryWrapper compact={compact}>
        <Swiper {...params} resizeObserver ref={ref}>
          {items.map((item, i) => {
            return (
              <SwiperSlide key={`slider_slide_${i}`}>
                <GalleryImage image={item} compact={compact} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="py-2 md:py-4 flex flex-row">
          <span className="m-0 text-c-gallery-slideColour font-bold">{`${activeSlide + 1}/${items?.length}`}</span>
          {items?.[activeSlide]?.caption && (
            <figcaption className="m-0 ml-4 text-c-gallery-caption">{items?.[activeSlide]?.caption}</figcaption>
          )}
        </div>
        <div ref={refThumbs} className="mt-4 md:mt-3 slider-thumbs inline-flex">
          {items.map((item, i) => {
            return (
              <button
                type="button"
                key={`slider_dot_${i}`}
                className={i === activeSlide ? 'active dot' : 'dot'}
                onClick={() => goTo(i)}
              >
                <ImageProxy
                  url={item?.url}
                  type="fixed"
                  noCrop
                  params={{
                    w: 72,
                    h: 72,
                    r: 'fill'
                  }}
                />
              </button>
            )
          })}
        </div>
      </GalleryWrapper>
      <GalleryButton className="hidden lg:inline ml-14" type="button" onClick={goNext}>
        <Icons.ArrowRight />
      </GalleryButton>
    </div>
  )
}

export default Gallery
