import React from 'react'
import { format } from 'date-fns'
import tw, { theme, css, styled } from 'twin.macro'
import { IntlContext } from 'react-intl'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
//
import { QuoteIcon } from '~images/icons/icons'
import { Heading, Label, SuperParagraph, Tag } from '~storybook/blocks/typography/index'
import { ImageProxy } from '~storybook/image/image'
import NavLink from '~utils/navlink'
import Icons from '~storybook/icons/index'
import FormattedText from '~utils/text'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const TertiaryStyle = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`text-c-tertiary-button-text font-thin py-0 mb-0 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent hover:text-c-tertiary-button-text hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 justify-start`}
  ${({ isSection }) => isSection && tw`text-c-card-content-lightText font-medium`}
  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const TertiaryStyleDark = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`text-c-card-content-text font-medium py-0 mb-0 mt-4 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent hover:text-c-card-content-hoverText hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 justify-start`}

  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const CardWrapper = styled.div`
  ${tw`block relative mb-8`}
  .gatsby-image-wrapper {
    ${tw`block relative`}
    height: ${({ twoCards }) => (twoCards ? '369px' : '240px')};

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      height: ${({ twoCards }) => (twoCards ? '212px' : '207px')};
    }
  }

  .title-line-trim {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .body-line-trim {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const CardWrapperImageBG = styled.div`
  ${tw`bg-c-card-content-bg block relative mb-8`}

  height: 590px;

  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    height: 532px;
  }

  .gatsby-image-wrapper {
    ${tw`block relative`}

    height: 590px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      height: 532px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: 1;

      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 90%);
    }
  }
`

const IconWrapper = styled.div`
  ${tw`bg-c-icon-wrapper-bg px-6 pt-8 pb-3 text-c-icon-wrapper-fg`}
  svg {
    width: 64px;
    height: 64px;
  }
`
const CardQuoteWrapper = styled.div`
  ${tw`block relative`}
  ${tw`bg-c-card-quote-wrapper-bg text-c-card-quote-wrapper-fg block w-full p-6 flex flex-col mb-8`}
  ${({ isSingle }) => (isSingle ? tw`justify-center` : tw`justify-start`)}

  ${({ noBackground }) => noBackground && tw`bg-transparent p-0 text-c-card-quote-wrapper-noBgFg mb-12`}
  ${({ noBackground }) =>
    !noBackground &&
    css`
      min-height: 320px;
    `}
  ${({ center }) =>
    center &&
    css`
      text-align: center;
      span,
      svg {
        margin: 0 auto;
        display: block;
      }
    `}

  svg {
    width: 60px;
    height: 60px;
    display: block;
    * {
      fill: ${theme`colors['c-card-quote-wrapper'].svg`};
    }
  }
`

const ContactImageWrapper = styled.div`
  height: 112px !important;

  ${tw`mb-7`}
  ${({ isLogo }) =>
    isLogo &&
    css`
      img {
        height: 82px !important;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        width: auto !important;
      }
    `}
`

const CardContactWrapper = styled.div`
  ${tw`block relative`}
  ${tw`bg-transparent border border-c-card-contact-border border-solid text-c-card-content-lightText block w-full p-6 lg:p-8 flex flex-col justify-between mb-8`}

  svg {
    width: 24px;
    height: 24px;
  }
`

const CardContent = styled.div`
  ${tw`block w-full p-6 flex flex-col justify-between`}
  ${({ absolute }) => absolute && tw`text-c-card-content-fg absolute bottom-0 left-0`}
  ${({ absolute }) => !absolute && tw`bg-c-card-content-bg text-c-card-content-fg`}

  ${({ absolute }) =>
    absolute &&
    css`
      min-height: 250px;
    `}
  ${({ absolute }) =>
    !absolute &&
    css`
      min-height: 200px;
    `}

  z-index: 1;

  button {
    ${tw`text-white`}
  }
`

const Card = ({
  type = '',
  isSingle = false,
  image = null,
  title = '',
  text = { value: '', format: 'html' },
  cta = null,
  date = null,
  category = '',
  links = null,
  quote = '',
  author = '',
  noBackground = false,
  center = false,
  icon = null,
  twoCards = false,
  contact = null,
  social = null,
  cssClass = null
}) => {
  const intl = React.useContext(IntlContext)
  const locale = intl?.locale

  const dateStr = date && `${format(date, 'd LLLL, yyyy', { locale: localesObj[locale] })}`

  let cardTypeContents

  const cardContent = (
    <div>
      {(dateStr || category) && (
        <div className="mb-2 flex justify-start">
          {category && (
            <div className="cursor-pointer no-underline hover:no-underline text-c-card-content-lightText hover:text-c-card-content-lightText justify-between">
              <Tag className="cursor-pointer text-c-card-content-textTag">{category}</Tag>
            </div>
          )}
          {date && (
            <div
              className={`${
                category ? 'ml-2' : ''
              } cursor-pointer no-underline hover:no-underline text-c-card-content-lightText hover:text-c-card-content-lightText justify-between`}
            >
              <Label className="cursor-pointer text-c-card-content-subText">{dateStr}</Label>
            </div>
          )}
        </div>
      )}
      {title && (
        <div className="cursor-pointer no-underline hover:no-underline text-white hover:text-white justify-between">
          <Heading className="mb-4 title-line-trim" level="h3" text={title} />
        </div>
      )}
      {text?.value && (
        <div className="mb-6 body-line-trim">
          <FormattedText text={text.value} format={text?.format || 'html'} />
        </div>
      )}
      {Array.isArray(links) &&
        links.map((link, idx) => (
          <TertiaryStyle key={`link-${link?.url}-${idx}`} to={link?.url} hasIcon>
            <div className="block bg-c-tertiary-button-bg text-c-tertiary-button-text p-2">
              {link?.label}
              {link?.external ? <Icons.ExternalLink /> : <Icons.ArrowRight />}
            </div>
          </TertiaryStyle>
        ))}
    </div>
  )

  const cardCtaButton =
    cta && cta?.url ? (
      <TertiaryStyle to={cta?.url} hasIcon>
        <div className="block bg-c-tertiary-button-bg text-c-tertiary-button-text p-2">
          {cta?.label || intl.formatMessage({ id: 'news.readMore' })}
          {cta?.external ? <Icons.ExternalLink /> : <Icons.ArrowRight />}
        </div>
      </TertiaryStyle>
    ) : null

  switch (type) {
    case 'card_image_bg':
      cardTypeContents = (
        <CardWrapperImageBG className="card_image_bg">
          {image && image?.url && (
            <NavLink to={cta?.url}>
              <ImageProxy
                objectFit="cover"
                {...{
                  ...image,
                  type: 'fluid',
                  params: {
                    mw: 380,
                    mh: 590,
                    g: 'sm',
                    r: 'fit'
                  }
                }}
              />
            </NavLink>
          )}
          <CardContent absolute>
            <NavLink
              className="cursor-pointer no-underline hover:no-underline text-white hover:text-white"
              to={cta?.url}
            >
              {(dateStr || category) && (
                <div className="mb-2 flex">
                  {category && (
                    <div>
                      <Tag className="cursor-pointer">{category}</Tag>
                    </div>
                  )}
                  {date && (
                    <div className={category ? 'ml-2' : ''}>
                      <Label className="cursor-pointer">{dateStr}</Label>
                    </div>
                  )}
                </div>
              )}
              {title && <Heading className="mb-4 title-line-trim" level="h3" text={title} />}
              {text?.value && (
                <div className="mb-6 body-line-trim">
                  <FormattedText text={text.value} format={text?.format || 'html'} />
                </div>
              )}
            </NavLink>
            {cta && cta?.url && (
              <TertiaryStyle to={cta?.url} hasIcon>
                <div className="block bg-c-tertiary-button-bg text-c-tertiary-button-text p-2">
                  {cta?.label || intl.formatMessage({ id: 'news.readMore' })}
                  {cta?.external ? <Icons.ExternalLink /> : <Icons.ArrowRight />}
                </div>
              </TertiaryStyle>
            )}
          </CardContent>
        </CardWrapperImageBG>
      )
      break
    case 'card_image_text':
      cardTypeContents = (
        <CardWrapper twoCards={twoCards} className="card_image_text">
          {image && image?.url && (
            <NavLink to={cta?.url}>
              <ImageProxy
                objectFit="cover"
                {...{
                  ...image,
                  type: 'fluid',
                  params: {
                    mw: twoCards ? 584 : 380,
                    mh: twoCards ? 584 : 380,
                    g: 'sm',
                    r: 'fit'
                  }
                }}
              />
            </NavLink>
          )}
          <CardContent>
            {Array.isArray(links) ? (
              cardContent
            ) : (
              <>
                <NavLink
                  className="flex flex-col flex-1 cursor-pointer no-underline hover:no-underline text-white hover:text-white justify-between"
                  to={cta?.url}
                >
                  {cardContent}
                </NavLink>
                {cardCtaButton}
              </>
            )}
          </CardContent>
        </CardWrapper>
      )
      break
    case 'card_icon_text':
      cardTypeContents = (
        <CardWrapper hasIcon={icon} className={cssClass}>
          {icon && (
            <NavLink to={cta?.url}>
              <IconWrapper>{icon}</IconWrapper>
            </NavLink>
          )}
          <CardContent>
            {Array.isArray(links) ? (
              <NavLink to={cta?.url} className="flex flex-col flex-1 cursor-pointer no-underline hover:no-underline text-white hover:text-white justify-between">
                {cardContent} 
              </NavLink>
            ) : (
              <>
                <NavLink to={cta?.url} 
                  className="flex flex-col flex-1 cursor-pointer no-underline hover:no-underline text-white hover:text-white justify-between"
                >
                  {cardContent}
                </NavLink>
                {cardCtaButton}
              </>
            )}
          </CardContent>
        </CardWrapper>
      )
      break
    case 'card_quote':
      cardTypeContents = (
        <CardQuoteWrapper isSingle={isSingle} noBackground={noBackground} center={center} className="card_quote">
          <div className="container max-w-7xl mx-auto">
            <QuoteIcon />
            {quote && <Heading className="font-thin mt-8 mb-2" level="h3" text={quote} />}
            {author && (
              <Label
                className={noBackground ? 'text-c-card-quote-author-noBgText' : 'text-c-card-quote-author-text'}
                text={author}
              />
            )}
          </div>
        </CardQuoteWrapper>
      )
      break
    case 'card_contact':
      cardTypeContents = (
        <CardContactWrapper className="card_contact">
          <div className="mb-4">
            {image && image?.url && (
              <ContactImageWrapper isLogo={image?.isLogo}>
                {!image?.isLogo ? (
                  <ImageProxy
                    objectFit="cover"
                    {...{
                      ...image,
                      type: 'fixed',
                      params: {
                        w: 112,
                        h: 112,
                        g: 'sm',
                        r: 'fit'
                      }
                    }}
                  />
                ) : (
                  <ImageProxy
                    objectFit="contain"
                    {...{
                      ...image,
                      type: 'fixed',
                      params: {
                        w: 217,
                        h: 82,
                        g: 'sm',
                        r: 'fit'
                      }
                    }}
                  />
                )}
              </ContactImageWrapper>
            )}

            {title && (
              <Heading className="mb-2 text-c-card-contact-title font-medium title-line-trim" level="h3" text={title} />
            )}
            {text?.value && (
              <div className="mb-6 text-c-card-contact-text body-line-trim">
                <FormattedText text={text.value} format={text?.format || 'html'} />
              </div>
            )}
            {contact && Object.keys(contact)?.length > 0 && (
              <div className="flex flex-col">
                {contact?.telephone && (
                  <div className="text-c-card-contact-title mb-1">
                    <span className="text-c-card-contact-icon mr-4">
                      <Icons.Telephone />
                    </span>
                    {contact?.telephone}
                  </div>
                )}
                {contact?.mobile && (
                  <div className="text-c-card-contact-title mb-1">
                    <span className="text-c-card-contact-icon mr-4">
                      <Icons.SmartPhone />
                    </span>
                    {contact?.mobile}
                  </div>
                )}
                {contact?.email && (
                  <div className="text-c-card-contact-title mb-1">
                    <NavLink className="font-thin" to={`mailto:${contact?.email}`}>
                      <span className="text-c-card-contact-icon mr-4">
                        <Icons.Mail />
                      </span>
                      {contact?.email}
                    </NavLink>
                  </div>
                )}
                {contact?.website && (
                  <div className="text-c-card-contact-title mb-1">
                    <NavLink className="font-thin" to={contact?.website}>
                      <span className="text-c-card-contact-icon mr-4">
                        <Icons.Website />
                      </span>
                      {contact?.website}
                    </NavLink>
                  </div>
                )}
              </div>
            )}
          </div>
          {social && typeof social === 'object' && Object.keys(social)?.length > 0 && (
            <div className="flex flex-row">
              {social?.facebook && (
                <NavLink to={social?.facebook}>
                  <span className="text-c-card-contact-icon mr-2">
                    <Icons.Facebook />
                  </span>
                </NavLink>
              )}
              {social?.instagram && (
                <NavLink to={social?.instagram}>
                  <span className="text-c-card-contact-icon mr-2">
                    <Icons.Instagram />
                  </span>
                </NavLink>
              )}
              {social?.linkedin && (
                <NavLink to={social?.linkedin}>
                  <span className="text-c-card-contact-icon mr-2">
                    <Icons.LinkedIn />
                  </span>
                </NavLink>
              )}
              {social?.twitter && (
                <NavLink to={social?.twitter}>
                  <span className="text-c-card-contact-icon mr-2">
                    <Icons.Twitter />
                  </span>
                </NavLink>
              )}
            </div>
          )}
        </CardContactWrapper>
      )
      break
    case 'card_newsfeed':
      cardTypeContents = (
        <CardWrapper twoCards={twoCards} className="card_newsfeed">
          {image && image?.url && (
            <NavLink to={cta?.url}>
              <ImageProxy
                objectFit="cover"
                {...{
                  ...image,
                  type: 'fluid',
                  params: {
                    mw: twoCards ? 584 : 380,
                    mh: twoCards ? 584 : 380,
                    g: 'sm',
                    r: 'fit'
                  }
                }}
              />
            </NavLink>
          )}
          <div className="my-4">
            <NavLink
              className="flex flex-col flex-1 cursor-pointer no-underline hover:no-underline text-c-card-content-lightText hover:text-c-card-content-lightText justify-between"
              to={cta?.url}
            >
              {(dateStr || category) && (
                <div className="mb-4 flex">
                  {category && (
                    <div>
                      <Tag className="cursor-pointer text-c-card-content-lightText">{category}</Tag>
                    </div>
                  )}
                  {date && (
                    <div className={category ? 'ml-2' : ''}>
                      <Label className="cursor-pointer text-c-card-content-text">{dateStr}</Label>
                    </div>
                  )}
                </div>
              )}
              {title && <Heading className="mb-4 font-medium title-line-trim" level="h3" text={title} />}
              {text?.value?.length ? <SuperParagraph text={text?.value} /> : null}
            </NavLink>

            {cta && cta?.url && (
              <TertiaryStyleDark to={cta?.url} hasIcon>
                <div className="block bg-c-tertiary-button-bg text-c-tertiary-button-text p-2">
                  {cta?.label || intl.formatMessage({ id: 'news.readMore' })}
                  {cta?.external ? <Icons.ExternalLink /> : <Icons.ArrowRight />}
                </div>
              </TertiaryStyleDark>
            )}
          </div>
        </CardWrapper>
      )
      break
    default:
      cardTypeContents = null
  }

  return cardTypeContents
}

export default Card
