import React from 'react'
import parse from 'date-fns/parse'
//
import Card from '~storybook/cards'

const ParagraphCardImage = ({
  fieldCardCallToAction,
  fieldCardDate,
  fieldCardImage,
  fieldCardImagePosition,
  fieldCardLinks,
  fieldCardTitle,
  fieldCardType,
  fieldCardDescription
}) => {
  const cta = {
    label: fieldCardCallToAction?.title,
    url: fieldCardCallToAction?.url?.path
  }

  const date = fieldCardDate?.value ? parse(fieldCardDate?.value, 'yyyy-MM-dd', new Date()) : null

  const links =
    fieldCardLinks?.map((link) => {
      return {
        label: link?.title,
        url: link?.url?.path,
        external: link?.url?.__typename === 'Drupal_ExternalUrl'
      }
    }) || []

  const image = {
    url: fieldCardImage?.entity?.thumbnail?.url
  }

  const type = fieldCardImagePosition === 'top' ? 'card_image_text' : 'card_image_bg'

  return (
    <Card
      type={type}
      image={image}
      date={date}
      title={fieldCardTitle}
      category={fieldCardType}
      links={links}
      cta={cta}
      text={fieldCardDescription}
    />
  )
}

export default ParagraphCardImage
