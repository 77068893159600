import React from 'react'
import tw from 'twin.macro'
//
import Card from './card'

export const CardSet = ({ cards, noGrid = false, cols = null, ...rest }) => {
  const gridCols = cols || (cards?.length === 2 ? 2 : 3)

  return Array.isArray(cards) && cards?.length > 0 ? (
    <div className="card_set_wrapper"
      {...rest}
      css={[
        !noGrid && tw`md:grid md:grid-cols-2 md:gap-x-8`,
        gridCols === 2 && tw`lg:grid-cols-2`,
        gridCols !== 2 && tw`lg:grid-cols-3`
      ]}
    >
      {cards?.map((card, i) => {
        return <Card key={card?.id || i} {...card} twoCards={cards?.length === 2} />
      })}
    </div>
  ) : null
}

export default CardSet
