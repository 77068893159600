import React, { useContext } from 'react'
//
import HeroBanner from '~storybook/hero-banner'
import SocialLinks from '~storybook/social-links'
import { SiteDataContext } from '~components/layout/wrappers'

export const Hero = ({ items = [] }) => {
  if (!items || !items.length) {
    return null
  }

  if (items.length > 1) {
    const heroItems = items.map(({ fieldTitle, fieldImage, fieldCta, fieldImageCopyright, fieldBody }) => {
      return {
        title: fieldTitle,
        image: {
          credits: fieldImageCopyright,
          url: fieldImage?.entity?.thumbnail?.url
        },
        cta: {
          url: fieldCta?.url?.path,
          label: fieldCta?.title
        },
        body: fieldBody
      }
    })

    return (
      <div className="mb-10">
        <HeroBanner items={heroItems} />
      </div>
    )
  }

  const {
    fieldTitle,
    fieldBody,
    fieldCta,
    fieldHeroBackground,
    fieldImagePosition,
    fieldImageCaption,
    fieldImageCopyright,
    fieldImage
  } = items[0]

  if (fieldImagePosition === 'bg' || fieldImagePosition === 'none') {
    const singleHeroData = {
      items: [
        {
          title: fieldTitle,
          image: {
            url: fieldImage?.entity?.thumbnail?.url
          }
        }
      ]
    }

    return (
      <div className="mb-10">
        <HeroBanner {...singleHeroData} />
      </div>
    )
  }

  const siteData = useContext(SiteDataContext)

  const { group } = siteData
  const {
    fieldSocialmediaFacebook,
    fieldSocialmediaLinkedin,
    fieldSocialmediaTwitter,
    fieldSocialmediaInstagram
  } = group

  const data = {
    title: fieldTitle,
    text: fieldBody?.value || '',
    image: {
      caption: fieldImageCaption,
      credits: fieldImageCopyright,
      url: fieldImage?.entity?.thumbnail?.url
    },
    cta: {
      url: fieldCta?.url?.path,
      label: fieldCta?.title
    },
    orientation: fieldImagePosition === 'left' ? 'left' : null,
    dark: fieldHeroBackground === 'dark',
    socialLinks: items[0]?.fieldShowSocialMedia ? (
      <SocialLinks
        hero
        links={{
          twitter: { url: fieldSocialmediaTwitter?.url?.path, label: 'Twitter' },
          facebook: { url: fieldSocialmediaFacebook?.url?.path, label: 'Facebook' },
          linkedin: {
            url: fieldSocialmediaLinkedin?.url?.path,
            label: 'LinkedIn'
          },
          instagram: { url: fieldSocialmediaInstagram?.url?.path, label: 'Instagram' }
        }}
      />
    ) : null
  }

  return (
    <div className="mb-10">
      <HeroBanner {...data} />
    </div>
  )
}

export default Hero
