import React from 'react'
//
import Card from '~storybook/cards'

export const ParagraphCardQuote = ({ fieldQuoteAuthor, fieldQuoteQuote, isSingle = true }) => {
  return (
    <section className="bg-white-500 w-full mb-8 relative">
      <Card
        type="card_quote"
        quote={fieldQuoteQuote}
        author={fieldQuoteAuthor}
        center
        noBackground={false}
        isSingle={isSingle}
      />
    </section>
  )
}

export default ParagraphCardQuote
