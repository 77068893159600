import React from 'react'
//
import Video from './video'

export const VideoBlock = ({ id, description, fullWidth = true }) => (
  <div className={`w-full ${!fullWidth && 'lg:w-8/12'}`}>
    <Video id={id} description={description} />
  </div>
)

export default VideoBlock
