import React from 'react'
import IframeResizer from 'iframe-resizer-react'
import parse from 'style-to-object';

const ParagraphGroupWebformEmbed = ({ fieldGroupForm = [], fieldUrlParam = '', fieldCss = '', language }) => {
  const DRUPAL_FORMS_URL = process.env.DRUPAL_FORMS_URL || 'https://content.fed.be'
  const CssFromContent = parse(fieldCss)
  const iframeResizerDefaults = {
    width: '1px', 
    minWidth: '100%'
  }
  const mergedCss = {...CssFromContent, ...iframeResizerDefaults}

  return (
    <IframeResizer
      log
      src={`${DRUPAL_FORMS_URL}/${language}/webform/${fieldGroupForm.targetId}/share/iframe-resizer/4.2.10?${fieldUrlParam}`}
      sandbox="allow-scripts allow-same-origin allow-forms"
      name="embedFrame"
      style={mergedCss}
    />
  )
}

export default ParagraphGroupWebformEmbed
