import React from 'react'
import tw, {css } from 'twin.macro'


const iFrame = ({ fieldUri, fieldTitle, fieldWidth, fieldHeight, fieldBreakpoints, fieldIframeAllow }) => {
  if (!fieldUri.url.path) {
    return null
  }

  const bkPointStyles = {
    Block: css`${tw`block`}`,
    
    Small: css`${tw`hidden sm:block`}`,
    Medium: css`${tw`hidden md:block`}`,
    Large: css`${tw`hidden lg:block`}`,
    XLarge: css`${tw`hidden xl:block`}`,

    FromSmall: css`${tw`hidden sm:block`}`,
    FromMedium: css`${tw`hidden md:block`}`,
    FromLarge: css`${tw`hidden lg:block`}`,
    FromXLarge: css`${tw`hidden xl:block`}`,

    TillSmall: css`${tw`block sm:hidden`}`,
    TillMedium: css`${tw`block md:hidden`}`,
    TillLarge: css`${tw`block lg:hidden`}`,
    TillXLarge: css`${tw`block xl:hidden`}`,
  }

  return (
    <div>
      <iframe
        title={fieldTitle}
        height={fieldHeight}
        width={`${fieldWidth}%`}
        src={fieldUri.url.path}
        frameBorder="0"
        css={[fieldBreakpoints && bkPointStyles?.[fieldBreakpoints]]}
        allow={fieldIframeAllow}
      />
    </div>
  )
}

export default iFrame
