import React from 'react'
import tw, { theme, styled } from 'twin.macro'
//
import { VideoBlock } from '~storybook/video'
import { ImageProxy } from '~storybook/image/image'

export const getID = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  if (match && match[2].length === 11) {
    return match[2]
  }

  return ''
}

const ImageCredits = styled.div`
  ${tw`absolute bottom-0 right-0 text-white py-2 px-3 text-sm z-10`}
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${theme`colors['c-media-gallery'].bg`};
    opacity: 0.9;
    z-index: -1;
  }
`

const imageParams = {
  mw: 790,
  mh: 444,
  g: 'sm',
  r: 'fill'
}

export const ParagraphMediaGallery = ({ fieldItems = [], hasSidebar = false }) => {
  return (
    <div>
      <div className="grid gap-4 grid-cols-2">
        {fieldItems?.map((item, idx) => (
          <div key={item.targetId} className="">
            {item?.entity?.fieldVideo && (
              <section className="bg-white-500 ">
                <VideoBlock id={getID(item?.entity?.fieldVideo)} fullWidth />
              </section>
            )}
            {item?.entity?.fieldImage && (
              <section className="bg-white-500">
                <figure>
                  <div className="relative block">
                    <ImageProxy
                      url={item?.entity?.fieldImage?.entity?.thumbnail?.url}
                      type="fluid"
                      params={imageParams}
                    />
                    {item?.entity?.fieldImage?.credits && (
                      <ImageCredits>{item?.entity?.fieldImage?.credits}</ImageCredits>
                    )}
                  </div>
                </figure>
              </section>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ParagraphMediaGallery
