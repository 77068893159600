import React from 'react'
//
import Timeline from '~storybook/timeline'

export const ParagraphTimeline = ({ fieldTimelineItems, hasSidebar = false }) => {
  if (!fieldTimelineItems) {
    return null
  }

  const cards = fieldTimelineItems
    ?.map((item) => item.entity)
    ?.map((entity) => {
      return {
        id: entity?.id,
        title: entity?.fieldTimelineFromTo,
        cardTitle: entity?.fieldTitle,
        cardText: entity?.fieldBody?.value,
        cta: {
          url: entity?.fieldCta?.url?.path,
          label: entity?.fieldCta?.title
        },
        image: {
          url: entity?.fieldMediaImage?.entity?.thumbnail?.url,
          width: entity?.fieldMediaImage?.entity?.thumbnail?.width,
          height: entity?.fieldMediaImage?.entity?.thumbnail?.height,
          credits: null
        }
      }
    })

  return (
    <section className="bg-white-500 w-full mb-8 relative">
      <Timeline compact={hasSidebar} cards={cards} />
    </section>
  )
}

export default ParagraphTimeline
