import React, { useState } from 'react'
import tw, { theme, styled } from 'twin.macro'
//
import { Heading, Paragraph, SuperParagraph } from '~storybook/blocks/typography/index'
import Button from '~storybook/button/index'
import Icons from '~storybook/icons/index'
import { ImageProxy } from '~storybook/image/image'
import NavLink from '~utils/navlink'
import useWindowDimensions from '~utils/responsive'
import { isSSR } from '~utils/ssr'
import FormattedText from '~utils/text'

const TertiaryStyle = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`text-c-vignette-text font-medium py-0 mb-0 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent hover:text-c-vignette-textHover hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 justify-start`}
  ${({ isSection }) => isSection && tw`text-c-vignette-textHover font-medium`}
  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const VignetteWrapper = styled.div`
  .content-image {
    width: 380px;
    max-width: 380px;
    min-height: 252px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      min-width: 510px;
      width: 100%;
      min-height: 338px;
    }
    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      min-width: 327px;
      width: 100%;
      min-height: 220px;
    }
  }
`
const ContentWrapper = styled.div`
  ${tw`absolute text-white px-4 py-4 top-0 left-0 flex flex-row items-end justify-between`}

  width: 100%;
  // height: ${({ active }) => (active ? '297px' : '276px')};
  height: 276px;
  z-index: 1;

  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    height: calc(50vw - 64px);
  }
  @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
    height: calc(100vw - 32px);
  }

  svg {
    width: 32px;
    height: 32px;
  }
`

const ImageWrapper = styled.div`
  ${tw`block relative`}

  &, .gatsby-image-wrapper {
    // height: ${({ active }) => (active ? '297px' : '276px')};
    height: 276px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      height: calc(50vw - 64px);
    }
    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      height: calc(100vw - 32px);
    }
  }

  .gatsby-image-wrapper {
    ${tw`block relative`}

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: 1;

      background-image: ${({ active }) =>
        active ? 'transparent' : 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 90%)'};
    }
  }
`

const CardLinks = ({ links }) => {
  if (!links || !links.length) {
    return null
  }

  return (
    <>
      {links.map((link, idx) => (
        <div key={`vignette-link-${link?.url}-${idx}`} className="mt-4">
          <TertiaryStyle to={link?.url} hasIcon>
            <div className="block">
              {link?.label}
              <Icons.ArrowRight />
            </div>
          </TertiaryStyle>
        </div>
      ))}
    </>
  )
}

const Vignette = ({ items }) => {

  if (!isSSR) {
    var _window = window,
        _paq = _window._paq;
  }

  
        
  const { width } = isSSR ? { width: 960 } : useWindowDimensions()
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)

  const groups = []
  items.forEach((item, i) => {
    let numberOfGroups
    if (width >= parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1) {
      numberOfGroups = 4
    } else if (width >= parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1) {
      numberOfGroups = 2
    } else {
      numberOfGroups = 1
    }
    const key = Math.floor(i / numberOfGroups)
    if (!groups?.[key]) {
      groups[key] = []
    }
    groups[key][i % numberOfGroups] = { ...item, id: `group_${key}_item_${i % numberOfGroups}` }
  })

  return (
    <VignetteWrapper>
      {Array.isArray(groups) &&
        groups?.map((group, i) => {
          const selectedGroupItem = group?.find((item) => item?.id === selectedItem) || false
          return (
            <div key={`group_${i}`} className="mb-4 lg:mb-8 md:mb-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-6 lg:gap-8">
                {Array.isArray(group) &&
                  group?.map((item) => (
                    <div key={item?.id} className="flex flex-col">
                      <div className="block relative">
                        <ImageWrapper active={item?.id === selectedItem}>
                          <ImageProxy
                            objectFit="cover"
                            {...{
                              ...item?.image,
                              type: 'fluid',
                              params: {
                                mw: 332,
                                mh: 332,
                                g: 'sm',
                                r: 'fill'
                              }
                            }}
                          />
                        </ImageWrapper>
                        {item?.id !== selectedItem && (
                          <ContentWrapper>
                            <div className="flex flex-col">
                              <Heading className="mb-0 pb-0" level="h4">
                                {item?.title}
                              </Heading>
                              <SuperParagraph className="mt-0 pt-0 hyphens-auto">{item?.subtitle}</SuperParagraph>
                            </div>
                            {item?.id !== selectedItem && (
                              <button
                                type="button"
                                className="outline-none focus:outline-none"
                                aria-labelledby={item?.id}
                                onClick={() => {
                                  setSelectedGroup(i)
                                  setSelectedItem(item?.id)
                                  _paq?.push(['trackEvent', 'Vignette', 'Open', item?.title]);
                                }}
                              >
                                <Icons.ArrowDown />
                              </button>
                            )}
                          </ContentWrapper>
                        )}
                      </div>
                      {item?.id === selectedItem && (
                        <div className="flex flex-col items-end mt-2 md:mb-8 lg:mb-12">
                          <button
                            type="button"
                            className="outline-none focus:outline-none"
                            aria-label='Close'
                            onClick={() => {
                              setSelectedGroup(null)
                              setSelectedItem(null)
                            }}
                          >
                            <Icons.Close style={{ width: '48px', height: '48px' }} />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {selectedGroup === i && (
                <>
                <div className={`lg:pb-20 grid grid-cols-1 md:gap-6 lg:gap-8 ${selectedGroupItem?.contentImage?.url ? 'lg:grid-cols-2' : ''} `}>
                  <div className={`pb-8 pt-4 md:pt-0 md:pb-5 lg:pb-0 lg:pt-6 md:px-20 lg:px-24`}>
                    <Heading level="h3">{selectedGroupItem?.title}</Heading>
                    {selectedGroupItem && selectedGroupItem?.content && <FormattedText text={selectedGroupItem.content} format='html' />}
                    {selectedGroupItem && selectedGroupItem?.links && <CardLinks links={selectedGroupItem?.links} />}

                    {selectedGroupItem &&
                      selectedGroupItem?.cta &&
                      selectedGroupItem?.cta?.url &&
                      selectedGroupItem?.cta?.label && (
                        <div className="mt-6">
                          <Button>{selectedGroupItem?.cta?.label}</Button>
                        </div>
                      )}
                  </div>
                  <div className="pb-6 md:pb-0 px-0 pr-6 md:px-20 lg:pt-0 md:px-6 lg:px-0">
                    <div className="content-image">
                      <ImageProxy
                        url={selectedGroupItem?.contentImage?.url}
                        type="fluid"
                        params={{
                          mw: 510,
                          mh: 338,
                          g: 'sm',
                          r: 'fill'
                        }}
                      />
                    </div>
                  </div>
                </div>
                </>
              )}
            </div>
          )
        })}
    </VignetteWrapper>
  )
}

export default Vignette
