import React from 'react'
//
import Gallery from '~storybook/gallery'

export const ParagraphPhotoGallery = ({ fieldItems, hasSidebar = false, isColumnRow = false }) => {
  if (!fieldItems) {
    return null
  }

  const photos = fieldItems
    ?.map((item) => item.entity)
    ?.filter((entity) => entity?.fieldImage?.entity?.thumbnail?.url)
    ?.map((entity) => {
      return {
        id: entity.id,
        caption: entity?.fieldImageCaption,
        credits: entity?.fieldImageCopyright,
        url: entity?.fieldImage?.entity?.thumbnail?.url
      }
    })

  return (
    <section className="bg-white-500 w-full mb-8 relative">
      <Gallery items={photos} compact={hasSidebar || isColumnRow} />
    </section>
  )
}

export default ParagraphPhotoGallery
