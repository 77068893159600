import React from 'react'
//
import { VideoBlock } from '~storybook/video'

export const getID = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  // First check if video link is a self hosted video file
  let regex = new RegExp(/[^\s]+(.*?).(mp4|ogg)$/);
  if (regex.test(url) == true) {
    // if so, return full url.
    return url
  }


  if (match && match[2].length === 11) {
    return match[2]
  }

  return ''
}

export const ParagraphVideo = ({ fieldVideo }) => (
  <section className="bg-white-500 w-full mb-8 relative">
    <VideoBlock id={getID(fieldVideo)} fullWidth />
  </section>
)

export default ParagraphVideo
