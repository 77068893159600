import React from 'react'
//
import { CardLocation } from '~storybook/cards'

const ParagraphAddressLocationBlock = ({ fieldImage, fieldDisplayMap, fieldContact }) => {
  if (!fieldContact || !fieldContact?.entity) {
    return null
  }

  const { title, fieldPicture, fieldEmail, fieldTelephoneNumber, fieldMobilePhoneNumber } = fieldContact?.entity
  const { addressLine1, addressLine2, postalCode, locality } = fieldContact?.entity?.fieldAddress

  let address = ''
  if (addressLine1) {
    address = `${addressLine1}<br />`
  }
  if (addressLine2) {
    address = `${address}${addressLine2}<br />`
  }
  if (postalCode) {
    address = `${address}${postalCode}<br />`
  }
  if (locality) {
    address = `${address}${locality}`
  }

  let telephone = ''
  if (fieldTelephoneNumber) {
    telephone = `${fieldTelephoneNumber}<br />`
  }
  if (fieldMobilePhoneNumber) {
    telephone = `${telephone}${fieldTelephoneNumber}`
  }

  return (
    <CardLocation
      title={title}
      address={address}
      telephone={telephone}
      email={fieldEmail}
      image={fieldImage?.entity?.thumbnail?.url}
      logo={fieldPicture?.entity?.thumbnail?.url}
    />
  )
}

export default ParagraphAddressLocationBlock
