import React from 'react'
import tw, {css } from 'twin.macro'
//
import ImageContent from '~storybook/image-content'

export const ParagraphImageContent = ({
  fieldTitlePrimary,
  fieldTitleSecondary,
  fieldAnchorLinkId,
  fieldBody,
  fieldImageCaption,
  fieldImagePosition,
  fieldImageCopyright,
  fieldEntityImage,
  fieldCta,
  hasSidebar = false,
  fieldBreakpoints = ""
}) => {
  const image = {
    caption: fieldImageCaption,
    credits: fieldImageCopyright,
    url: fieldEntityImage?.entity?.thumbnail?.url,
    width: fieldEntityImage?.entity?.thumbnail?.width,
    height: fieldEntityImage?.entity?.thumbnail?.height
  }

  const text = {
    content: fieldBody?.value,
    format: fieldBody?.format || 'html',
    title: fieldTitlePrimary,
    subtitle: fieldTitleSecondary
  }

  const cta = {
    label: fieldCta?.title,
    link: fieldCta?.url?.path
  }

  const bkPointStyles = {
    Block: css`${tw`block`}`,
    
    Small: css`${tw`hidden sm:block`}`,
    Medium: css`${tw`hidden md:block`}`,
    Large: css`${tw`hidden lg:block`}`,
    XLarge: css`${tw`hidden xl:block`}`,

    FromSmall: css`${tw`hidden sm:block`}`,
    FromMedium: css`${tw`hidden md:block`}`,
    FromLarge: css`${tw`hidden lg:block`}`,
    FromXLarge: css`${tw`hidden xl:block`}`,

    TillSmall: css`${tw`block sm:hidden`}`,
    TillMedium: css`${tw`block md:hidden`}`,
    TillLarge: css`${tw`block lg:hidden`}`,
    TillXLarge: css`${tw`block xl:hidden`}`,
  }

  return (
    <section className={`bg-c-image-and-content-section-bg w-full mt-12 mb-12 relative`} css={[fieldBreakpoints && bkPointStyles?.[fieldBreakpoints]]}>
      <ImageContent image={image} text={text} cta={cta} orientation={fieldImagePosition} id={fieldAnchorLinkId} noCrop />
    </section>
  )
}

export default ParagraphImageContent
